
<script lang='ts'>
  import { alertData } from './modals';
  import Frame from '../components/frame.svelte';
  import Chrome from '../components/chrome.svelte';

  let onConfirm = null;

  alertData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    alertData.set(null);
  }
</script>

{#if $alertData}
<div class='modal-container'>
  <div class='modal'>
    <Frame>
      {#if $alertData.title}
        <div class='header'>
          <h1>{$alertData.title}</h1>
        </div>
      {/if}

      {#if $alertData.text}
        <div class='body'>
          <p>{$alertData.text}</p>
        </div>
      {/if}

      <div class='functions'>
        <Chrome chroming='gold' edge='circle' thickness='thin' click={confirm}>
          <div class='button'>{$alertData.buttonText || "Confirm"}</div>
        </Chrome>
      </div>
    </Frame>
  </div>
</div>
{/if}

<style lang='less'>
  :global(.modal-container) {
    position: absolute;
    z-index: 1111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--light-font);

    :global(.frame){
      max-width: 600px;
    }

    :global(.modal){
      width: fit-content;
      background: var(--background-colour);
      color: var(--light-font);
      margin: auto;
      margin-top: 100px;

      :global(.header){
        display: flex;
        align-items: center;
        justify-content: space-between;
/*        padding: 0 20px;*/

        :global(h1){
          margin-bottom: 10px;
          font-size: 2em;
        }
      }

      :global(.body){
/*        padding: 0 20px;*/
        margin-bottom: 20px;

        :global(input){
          width: 100%;
        }
      }

      :global(.functions){
        display: flex;
/*        margin: 20px;*/
        bottom: 0;
        justify-content: flex-end;
        margin-top: 20px;
        grid-gap: 20px;
      }

      :global(.button){
        font-size: 20px;
        padding: 6px 12px;
      }
    }
  }
</style>