import { actionHistory } from '../../store';
import type { Modifier, ModifierParams } from './modifier';
import { StatusEffect, createModifier } from './status-effects';
import { createItem } from './items';
import { createTrait } from './traits';
import type { Item, Items } from './item.type';
import type { Damage } from './damage.type';
import { type DieCreation, DieType } from './die.type';

export type Player = {
  title: string;
  text?: string;
  id: string;
  type?: string;
  archetype?: string;
  healthMax: number;
  rerollMax: number;
  dice: Array<DieCreation>;
  colour: string;

  statusEffects: Array<Modifier>;
  traits: Array<Modifier>;
  healthCurrent?: number;

  level: number;
  experience: number;
  money: number;
  reputation: number;
  inventory: Array<Item>;

  takeDamage?: Function;
}

export type PlayerDefinition = {
  title: string;
  text: string;
  type: string;
  healthMax: number;
  rerollMax?: number;
  colour?: string;
  dice?: Array<DieType>;
  dice1?: Array<DieType>;
  dice2?: Array<DieType>;
  dice3?: Array<DieType>;

  archetypes: Array<any>;

  strengths?: Array<string>;
  weaknesses?: Array<string>;
}

export function createPlayerInstance(type: string, archetype?: string) {
  let player = players.find(player => {
    return player.type == type;
  });

  if(!player){
    console.error(`No player with type: ${type}`);
    return;
  }

  var instance: Player = JSON.parse(JSON.stringify(player));
  instance.id = 'player';
  instance.type = type;
  instance.archetype = archetype;
  instance.healthCurrent = instance.healthMax;

  instance.level = 0;
  instance.experience = 0;
  instance.reputation = -5;
  instance.money = 150;

  instance.inventory = [];
  instance.statusEffects = [];
  instance.traits = [];

  if (archetype){
    addTrait(instance, archetype);
  }

  return instance;
}

export function clonePlayer(player: Player): Player {
  const newPlayer = {
    ...player,
  };

  newPlayer.inventory = player.inventory.map(mod => {
    return mod.clone(newPlayer);
  });
  newPlayer.statusEffects = player.statusEffects.map(mod => {
    return mod.clone(newPlayer);
  });
  newPlayer.traits = player.traits.map(mod => {
    return mod.clone(newPlayer);
  });

  return newPlayer;
}


export function healthRemove(damage: Damage){
  damage.target.healthCurrent -= damage.value;

  if (damage.target.takeDamage) {
    damage.target.takeDamage(damage);
  }
  if(damage.value > 0){
    actionHistory.push(`${damage.value} pure damage to ${damage.target.title}`);
  }

  var event = new CustomEvent('createParticle', {
    detail: {
      type: 'text',
      animation: 'fall',
      value: damage.value,
      spawnTarget: '.portrait.'+damage.target.id,
      colour: 'red'
    }
  });
  window.dispatchEvent(event);

  return damage;
}

export function dealDamage(damage: Damage){
  // Damage Out modifiers
  getModifiers(damage.source, 'damageOut').forEach(mod => {
    mod.damageOut(damage);
  });

  // Damage In modifiers
  getModifiers(damage.target, 'damageIn').forEach(mod => {
    mod.damageIn(damage);
  });

  damage.target.healthCurrent -= damage.value;
  if (damage.target.takeDamage){
    damage.target.takeDamage(damage);
  }
  if(damage.value > 0){
    actionHistory.push(`${damage.value} damage to ${damage.target.title}`);

    var event = new CustomEvent('createParticle', {
      detail: {
        type: 'text',
        animation: 'fall',
        value: damage.value,
        spawnTarget: '.portrait.'+damage.target.id,
        colour: 'red'
      }
    });
    window.dispatchEvent(event);
  }

  return damage;
}

export function healDamage(healing: Damage){
  // Damage Out modifiers
  /*healing.source.statusEffects.filter(mod => 'healingOut' in mod)
  .forEach(mod => {
    mod.healingOut(healing);
  });*/

  // Healing In modifiers
  getModifiers(healing.target, 'healingIn').forEach(mod => {
    mod.healingIn(healing);
  });

  var value = healing.value;
  if(healing.target.healthCurrent + healing.value > healing.target.healthMax){
    value = healing.target.healthMax - healing.target.healthCurrent;
  }

  healing.target.healthCurrent += value;
  if(value > 0){
    actionHistory.push(`${healing.target.title} healed ${value} health.`);
  }

  if(healing.target.healthCurrent > healing.target.healthMax){
    healing.target.healthCurrent = healing.target.healthMax;
  }

  var event = new CustomEvent('createParticle', {
    detail: {
      type: 'text',
      animation: 'rise',
      value: value,
      spawnTarget: '.portrait.'+healing.target.id,
      colour: 'green'
    }
  });
  window.dispatchEvent(event);

  return healing;
}

export function addModifier(target: Player, type: StatusEffect, stacks: number, params?: ModifierParams){
  getModifiers(target, 'modifierAdd').forEach(mod => {
    stacks = mod.modifierAdd(type, stacks, params);
  });

  if(stacks > 0){
    let modifier = target.statusEffects.find(modifier => modifier.type == type);
    //if target has modifier
    if(modifier){
      modifier.update(stacks, params);
    }
    else{
      modifier = createModifier(target, type, stacks, params);
      target.statusEffects.push(modifier);
      target.statusEffects = target.statusEffects.sort((a, b) => {
        return b.priority - a.priority;
      });
    }

    var event = new CustomEvent('createParticle', {
      detail: {
        type: 'icon',
        animation: 'grow',
        value: modifier.icon,
        spawnTarget: '.portrait.'+target.id
      }
    });
    window.dispatchEvent(event);
  }
}

export function getModifiers(player: Player, type?: string) : Array<Modifier> {
  const mods = player.statusEffects.concat(player.inventory).concat(player.traits);
  mods.sort((a, b) => {
    return a.priority - b.priority;
  });

  if(type){
    return mods.filter((mod: Modifier) => type in mod);
  }
  else{
    return mods
  }
}

export function addMoney(target, value){
  target.money += value;
}

export function hasItem(player: Player, type: Items) {
  return player.inventory.find(item => item.type == type);
}
export function removeItem(player: Player, type: Items) {
  if(!hasItem(player, type)){
    return;
  }

  const item = player.inventory.find(item => item.type == type);
  if (item) {
    item.stacks --;
    if(item.stacks <= 0){
      player.inventory.splice(player.inventory.indexOf(item), 1);
    }
  }
}
export function addItem(player: Player, type: Items) {
  if(hasItem(player, type)){
    const item = player.inventory.find(item => item.type == type);
    item.stacks ++;
    return;
  }

  const item = createItem(type);
  item.owner = player;
  if (item) {
    player.inventory.push(item);
  }
}

export function hasTrait(player: Player, type: string) {
  return player.traits.find(trait => trait.type == type);
}
export function removeTrait(player: Player, type: string) {
  if (!hasTrait(player, type)) {
    return;
  }

  const trait = player.traits.find(trait => trait.type == type);
  if (trait) {
    player.traits.splice(player.traits.indexOf(trait), 1);
  }
}
export function addTrait(player: Player, type: string) {
  if(hasTrait(player, type)){
    return;
  }

  const trait = createTrait(player, type);
  trait.owner = player;
  if (trait) {
    player.traits.push(trait);
  }
}

export function changeReputation(player: Player, value: number) {
  player.reputation += value;
  if(player.reputation > 10){
    player.reputation = 10;
  }
  if(player.reputation < -10){
    player.reputation = -10;
  }
}

export const players: Array<PlayerDefinition> = [
  {
    title: 'Fighter',
    text: 'A skilled martial artist who specialises in melee combat, possessing exceptional strength, endurance, and tactical awareness in battle.',
    type: 'fighter',
    healthMax: 10,
    rerollMax: 2,
    dice1: [DieType.Axe, DieType.Longsword, DieType.Hammer, DieType.Spear],
    dice2: [DieType.Bracer, DieType.Shield, DieType.Helmet, DieType.Net],
    dice3: [DieType.Resilience, DieType.Strength, DieType.Speed, DieType.Reflex],
    archetypes: [{
      title: 'Barbarian',
      text: 'A savage warrior who specializes in using a two-handed weapon in combat, possessing exceptional strength, endurance, and ferocity in battle.',
      id: 'barbarian',
    }, {
      title: 'Knight',
      text: 'A heavily armored warrior who specializes in using a sword and shield in combat, possessing exceptional strength, endurance, and tactical awareness in battle.',
      id: 'knight',
    }, {
      title: 'Warrior',
      text: 'A hammer wielding warrior who specializes in using a two-handed weapon in combat, possessing exceptional strength, endurance, and ferocity in battle.',
      id: 'warrior',
    }, {
      title: 'Gladiator',
      text: 'Armed with a net and trident, the gladiator excels in agile, long-range combat, using cunning ensnarement tactics and precision strikes to conquer opponents in the arena.',
      id: 'gladiator',
    }],
    colour: '#880000',
  }, {
    title: 'Rogue',
    text: 'A stealthy martial artist who specializes in using surprise attacks, quick movements, and possessing exceptional agility, cunning, and reflexes.',
    type: 'rogue',
    healthMax: 10,
    rerollMax: 2,
    dice1: [DieType.Shivs, DieType.Dagger, DieType.Shortsword, DieType.Javellin],
    dice2: [DieType.Flask, DieType.Cloak, DieType.Bracer, DieType.Amulet],
    dice3: [DieType.Reflex, DieType.Stealth, DieType.Talented, DieType.Intelligence],
    archetypes: [{
      title: 'Bladestorm',
      text: 'An agile assassin known for their incredible speed and precision, unleashing a relentless storm of deadly daggers upon their foes.',
      id: 'bladestorm',
    }, {
      title: 'Shadow Walker',
      text: 'A stealthy rogue assassin who thrives on the element of surprise, gaining powerful bonuses from the shadows.',
      id: 'shadow-walker',
    }, {
      title: 'Fencer',
      text: 'A master of the sword, adept at one-on-one combat with unparalleled finesse and effectiveness.',
      id: 'fencer',
    }, {
      title: 'Spell Slinger',
      text: 'A novice of elemental magic, skilled in weapon infusion and ranged attacks, harnessing the elements for tactical advantages in combat.',
      id: 'spell-slinger',
    }],
    colour: '#0acc00',
  },{
    title: 'Elementalist',
    text: 'A spellcaster who has mastered the art of manipulating the elements to unleash powerful spells and abilities in battle, using their magical prowess to control and shape the battlefield to their advantage.',
    type: 'elementalist',
    healthMax: 10,
    rerollMax: 2,
    dice1: [DieType.Wand, DieType.Crystal, DieType.Staff, DieType.Tome],
    dice2: [DieType.Bracer, DieType.Circlet, DieType.Amulet, DieType.Earth],
    dice3: [DieType.Intelligence, DieType.Mana, DieType.Arcana, DieType.Resilience],
    archetypes: [{
      title: 'Anarchist',
      text: 'A volatile elemental spell caster, harnessing the fury of the elements, and embracing self-inflicted elemental harm for extraordinary power.',
      id: 'anarchist',
    }, {
      title: 'Mana Born',
      text: 'A mage who taps into the essence of mana, accumulating power over multiple spell casts to unleash devastating magic.',
      id: 'mana-born',
    }, {
      title: 'Wielder',
      text: 'Master of the core elements, with the rare ability to blend different elemental forces, forging destructive and unpredictable spell combinations.',
      id: 'wielder',
    }, {
      title: 'Dryad',
      text: 'A mage whose connection to the earth fuses elemental power with their skin, providing a unique blend of physical and magical defense through time-woven spells.',
      id: 'dryad',
    }],
    colour: '#0013cc',
  },{
    title: 'Ranger',
    text: 'A ranger is a skilled archer and summoner who uses their expertise in wilderness survival and tracking, along with their trusty bow and summoned familiar to vanquish their enemies.',
    type: 'ranger',
    healthMax: 10,
    rerollMax: 2,
    dice1: [DieType.Bow, DieType.Shortsword, DieType.Crossbow, DieType.Blowgun],
    dice2: [DieType.Quiver, DieType.Bandolier, DieType.Case, DieType.AmmoBag],
    dice3: [DieType.Strength, DieType.Speed, DieType.Reflex, DieType.Rhythmic],
    archetypes: [{
      title: 'Archer',
      text: 'A master of the bow, excelling in precision and arrow management, delivering deadly volleys with unparalleled accuracy.',
      id: 'archer',
    }, {
      title: 'Tamer',
      text: 'A skilled ranger with a loyal wolf companion, the Tamer dynamically evolves alongside their companion, unlocking diverse strategies and synergies in combat.',
      id: 'tamer',
    }, {
      title: 'Arbelist',
      text: 'A crossbow specialist, the Arbelist employs heavy bolts to deal devastating piercing damage, dominating foes with expert marksmanship.',
      id: 'arbelist',
    }, {
      title: 'Piper',
      text: 'Wielding a blowpipe for both music and darts, the Piper mesmerizes enemies with enchanting tunes while tactically delivering poisonous projectiles from a distance.',
      id: 'piper',
    },],
    colour: '#888800',
  }
].sort((a,b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
});
