import type { traitDefinition } from '../trait.type';
import { type Player, healDamage, addModifier } from "../player.type";
import { Die, type DieCreation, invokeDie } from "../die.type";
import { Trait } from '../trait.type';
import type { Technique } from '../techniques.type';
import { StatusEffect } from '../status-effects';
import { ModifierPriority } from '../modifier';

export const elementals: Array<traitDefinition> = [
  {
    type: 'elemental-affinity-ice',
    title: 'Elemental Affinity (Ice)',
    text: 'Immune to Freezing and Stunned.',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number) {
        if (type == StatusEffect.Freezing || type == StatusEffect.Stunned) {
          return 0;
        }
        return stacks;
      }
    }
  }, {
    type: 'elemental-affinity-fire',
    title: 'Elemental Affinity (Fire)',
    text: 'Immune to Burning and Purge.',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number) {
        // TODO, prevent purge
        if (type == StatusEffect.Burning) {
          return 0;
        }
        return stacks;
      }
    }
  }, {
    type: 'elemental-affinity-earth',
    title: 'Elemental Affinity (Earth)',
    text: 'Immune to Brittle and Clumsy.',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number) {
        if (type == StatusEffect.Brittle || type == StatusEffect.Clumsy) {
          return 0;
        }
        return stacks;
      }
    }
  }, {
    type: 'elemental-affinity-lightning',
    title: 'Elemental Affinity (Lightning)',
    text: 'Immune to Spark and Shock.',
    icon: 'trait',
    class: class extends Trait {
      modifierAdd(type: string, stacks: number) {
        if (type == StatusEffect.Spark || type == StatusEffect.Shocked) {
          return 0;
        }
        return stacks;
      }
    }
  }, {
    type: 'elemental-synergy',
    title: 'Elemental Synery',
    text: 'Lowers the level of each die by 1.',
    icon: 'trait',
    class: class extends Trait {
      init(): void {
        this.priority = ModifierPriority.First;
      }

      getDice(dice: Array<Die>): Array<DieCreation> {
        dice.forEach(die => {
          die.level --;
        });
        return [];
      }
    }
  }
];