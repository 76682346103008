<script lang="ts">
  import { glossary } from '../../store';
  import { players } from "../models/player.type";
  import { enemies, special, bosses } from "../models/enemy.type";
  import { techniques } from "../models/techniques.type";
  import { faces } from "../models/face.type";
  import { modifiers } from "../models/status-effects";
  import { items } from "../models/items";
  import { dieCollection } from "../models/die.type";
  import { environments } from "../models/environment.type";
  import type { Die as DieObject } from "../models/die.type";
  import { createDie } from "../models/die.type";
  import DieElement from "../dice-tray/die.svelte";

  import Die from "./components/die.svelte";
  import Player from "./components/player.svelte";
  import Enemy from "./components/enemy.svelte";
  import Technique from "./components/technique.svelte";
  import Item from "./components/item.svelte";
  import Environment from "./components/environment.svelte";
  import Modifier from "./components/modifier.svelte";
  import Gameplay from "./components/gameplay.svelte";
  import Face from "./components/face.svelte";
  import Icon from '../icon/icon.svelte';

  var glossaryOpen = true;
  glossary.open.subscribe(val => {
    glossaryOpen = val;
  });

  type Category = {
    title: string,
    data?: any[],
    search?: boolean
  };

  let selected: Category;
  let searchTerm: string = '';
  let tags: Array<string> = [];
  let searchDie: Array<DieObject> = [];

  const categories: Array<Category> = [
    {
      title: 'Gameplay',
      data:[0]
    },{
      title: 'Heroes',
      data: players
    },{
      title: 'Die',
      search: true,
      data: Object.keys(dieCollection).map(key => {
        var die = clone(dieCollection[key]);
        die.title = key;
        return die;
      })
      .sort((a,b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Faces',
      search: true,
      data: faces.sort((a,b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Status Effects',
      search: true,
      data: Object.keys(modifiers).map(key => {
        var modifier = clone(modifiers[key]);
        modifier.title = key;
        modifier.class = modifiers[key].class;
        return modifier;
      })
      .sort((a,b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Enemies',
      search: true,
      data: enemies.concat(special).concat(bosses).sort((a,b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Techniques',
      search: true,
      data: techniques.sort((a,b) => {
        if (a.ingredients.join() < b.ingredients.join()) {
          return -1;
        }
        if (a.ingredients.join() > b.ingredients.join()) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Items',
      search: true,
      data: items.sort((a,b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    },{
      title: 'Environments',
      search: true,
      data: environments.filter(environment => {
        return environment.title != 'Default';
      })
      .sort((a,b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    }
  ];

  function select(category){
    selected = category;
    tags = [];
    glossary.selected.set(category.title);
    searchTerm = '';
  }
  select(categories[0]);

  function clone(item){
    return JSON.parse(JSON.stringify(item));
  }

  function close(){
    glossary.open.set(false);
  }

  function toggleTag(tag: string){
    if(tags.includes(tag)){
      tags.splice(tags.indexOf(tag), 1);
      tags = [...tags];
    }
    else{
      tags = [...tags, tag];
    }
  }

  glossary.selected.subscribe(val => {
    if(val){
      categories.forEach(category => {
        if(category.title.toLowerCase() == val.toLowerCase()){
          select(category);
        }
      });
    }
  });

  glossary.searchTerm.subscribe(val => {
    searchTerm = val;
  });

  glossary.searchDie.subscribe(val => {
    if (val && val.length) {
      searchDie = val.map((die, index) => {
        return die;
      });
    }
    else{
      searchDie = [];
    }
  });

  const clearDie = () => {
    glossary.searchDie.set([]);
  }
</script>

{#if glossaryOpen}
  <div class='container'>
  <div class='header'>
    <h1>Glossary</h1>
    <div on:click={close} on:keydown={()=>{}}>
      <Icon name='broken'/>
    </div>
  </div>
  <div class='categories'>
    {#each categories as category}
    <div class='category' on:click={()=>select(category)} on:keydown={()=>{}}>
      {category.title}
    </div>
    {/each}
  </div>

  <div class='information'>
    <div class='title'>
      <h2>{selected.title}</h2>

      {#if selected.search}
        <input class='search' type='text' bind:value={searchTerm} placeholder="Search"/>
      {/if}
    </div>

    <div class='content'>
      <div class='padding'>&nbsp;</div>

      {#if selected.data && selected.data.length}
        {#if selected.title == "Gameplay"}
          <Gameplay/>
        {/if}

        {#if selected.title == "Heroes"}
          {#each selected.data as item}
            <Player data={item}/>
          {/each}
        {/if}

        {#if selected.title == "Die"}
          {#each selected.data as item}
            {#if item.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Die data={item}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Faces"}
          {#each selected.data as item}
            {#if item.id.toLowerCase().includes(searchTerm.toLowerCase())}
              <Face data={item}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Enemies"}
          {#each selected.data as item}
            {#if item.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Enemy data={item}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Status Effects"}
          <div class='tags'>
            <button class='tag {tags.includes('boon') ? "selected" : ""}' on:click={() => {toggleTag('boon');}}>Positive</button>
            <button class='tag {tags.includes('bane') ? "selected" : ""}' on:click={() => {toggleTag('bane');}}>Negative</button>
          </div>
          {#each selected.data as item}
            {#if item.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Modifier data={item} tags={tags}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Techniques"}
          {#if searchDie.length}
            <div>
              <div class='die-list'>
                {#each searchDie as die, index}
                  <div class='die-container'>
                    <DieElement die={die} index={index}/>
                  </div>
                {/each}
                <input class='btn' type='button' value='clear' on:click={clearDie}/>
              </div>
            </div>
          {/if}

          {#each selected.data as item}
            {#if item.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Technique data={item} searchDie={searchDie}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Items"}
          {#each selected.data as item}
            {#if item.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Item data={item}/>
            {/if}
          {/each}
        {/if}

        {#if selected.title == "Environments"}
          {#each selected.data as environment}
            {#if environment.title.toLowerCase().includes(searchTerm.toLowerCase())}
              <Environment data={environment}/>
            {/if}
          {/each}
        {/if}
      {/if}
    </div>
  </div>
</div>
{/if}


<style lang='less'>
  @import "../../defines.less";

  .container{
    background: var(--background-colour);
    border: 2px solid var(--background-colour-light);
    display: grid;
    grid-template-rows: 68px 1fr;
    grid-template-columns: 200px 1fr;
    width: min(95%, 1200px);
    height: 80%;
    margin-top: 10%;
    z-index: 11111;

    @media @medium{
      grid-template-columns: 150px 1fr;
    }
    @media @small{
      grid-template-columns: 100px 1fr;
    }

    .header{
      grid-column: span 2;
      border: 2px solid var(--background-colour-light);
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 35px;
      padding: 0 10px;
    }

    .categories{
      border: 2px solid var(--background-colour-light);
      background: var(--background-colour-dark);

      .category{
        padding: 10px;
        border: 2px solid var(--background-colour-light);
        margin: 0;
        background: var(--background-colour);
      }
    }

    .tags{
      display: flex;
      flex-direction: row;
      justify-content: center;
      grid-gap: 10px;
      padding: 5px 0;

      .tag{
        border: 2px solid var(--background-colour-light);
        &.selected{
          background: var(--background-colour-light);
        }
      }
    }

    .information{
      border: 2px solid var(--background-colour-light);
      position: relative;
      overflow: hidden;

      .title{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: var(--background-colour);
        z-index: 2;
        border: 4px solid var(--background-colour-light);
        padding: 10px;
        display: flex;
        justify-content: space-between;

        h2{
          font-size: 2em;
          text-align: left;
        }

        .search{
          border-radius: 4px;
          border-style: solid;
          padding: 10px;
        }
      }

      .content{
        .padding{
          opacity: 0;
          font-size: 2em;
          padding: 10px;
          border: 4px solid var(--background-colour-light);
          height: 39px;
        }

        height: 100%;
        overflow: auto;
      }

      .die-list{
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        padding: 10px;
      }
    }
  }
</style>