import type { Player } from './player.type';
import { actionHistory } from '../../store';
import type { Technique } from './techniques.type';
import type { Damage } from './damage.type';
import type { Die, DieCreation} from './die.type';
import type { DieFace } from './face.type';
import type { Stances } from './status-effects/stance';
import type { AmmoType } from './techniques/ammo';
import type { Songs } from './status-effects/song';
import type { StatusEffect } from './status-effects';
let count = 1;

export interface ModifierParams {
  imbue?: StatusEffect;
  infusion?: DieFace;
  ammoType?: AmmoType;
  stance?: Stances;
  recursive?: boolean;
  role?: 'performer' | 'listener';
  song?: Songs;
  duration?: number;
}

// priority list for modifiers
export enum ModifierPriority {
  First = 0,
  Early = 1,
  Middle = 5,
  Late = 10,
  Last = 11
};

export class Modifier {
  title: string;
  text: string;
  icon: string;
  bonusIcon?: string;
  stacks: number;
  id: number;
  type: string;
  tags: Array<string>;
  owner: Player;
  priority: ModifierPriority;
  duration: number;
  delay: boolean;
  properties: any;

  rollStart?(diceElements: Array<HTMLElement>, dice: Array<Die>, roll: number) : void;
  rollMid?(diceElements: Array<HTMLElement>, dice: Array<Die>, promises: Array<Promise<void>>) : void;
  rollEnd?(diceElements: Array<HTMLElement>, dice: Array<Die>) : void;
  getTechniques?(techniques: Array<Technique>) : Array<Technique>;
  getDice?(dice: Array<DieCreation>): Array<DieCreation>;
  canUseTechnique?(technique: Technique, player: Player, enemy: Player) : {able: boolean};
  canInterrupt?(technique: Technique, player: Player, enemy: Player) : {able: boolean};
  useTechnique?(technique: Technique, target: Player, source: Player, die: Array<Die>) : void;
  actionPhaseStart?() : void;
  endTurn?() : void;
  beforeStartTurn?() : void;
  startTurn?({activeDie, rerollMax, activePlayer, inactivePlayer}) : {activeDie: Array<Die>, rerollMax: number, activePlayer: Player, inactivePlayer: Player} | void;
  checkDied?() : void;
  damageOut?(damage: Damage) : void;
  damageIn?(damage: Damage) : void;
  healingIn?(healing: Damage) : void;
  modifierAdd?(type: string, stacks: number, params: ModifierParams) : number;
  modifyNotches?(notches: Array<string>): void;

  getHTML?() : string;
  onClick?() : void;

  constructor(mod?: Modifier) {
    this.id = count;
    count ++;

    if(mod){
      this.title = mod.title;
      this.text = mod.text;
      this.icon = mod.icon;
      this.stacks = mod.stacks;
      this.type = mod.type;
      this.tags = [...(mod.tags || [])];
      this.owner = mod.owner;
      this.priority = mod.priority || ModifierPriority.Middle;
      this.duration = mod.duration;
      this.delay = mod.delay;
      // copy properties object
      this.properties = { ...mod.properties };
    }
    else{
      this.title = 'Modifier';
      this.text = 'Modifier';
      this.icon = 'placeholder';
      this.stacks = 1;
      this.type = 'modifier';
      this.tags = [];
      this.owner = null;
      this.priority = 0;
      this.duration = 0;
      this.delay = false;
      this.properties = {};
    }

    /* if (this.owner){
      this.init();
    } */
  }

  clone(newOwner: Player){
    var clone = new (Object.getPrototypeOf(this)).constructor(this);
    clone.title = this.title;
    clone.text = this.text;
    clone.icon = this.icon;
    clone.stacks = this.stacks;
    clone.type = this.type;
    clone.priority = this.priority;
    clone.duration = this.duration;
    clone.delay = this.delay;
    clone.tags = [...this.tags];
    clone.properties = {...this.properties};
    clone.owner = newOwner;

    return clone;
  }

  init(params?: ModifierParams){
    if (actionHistory){
      actionHistory.push(`Created ${this.stacks} ${this.type} on ${this.owner.title}`);
    }
    this.update(0, params);
  }

  update(stacks: number, params?: ModifierParams){
    if(stacks){
      this.stacks += stacks;
      if (actionHistory) {
        actionHistory.push(`Added ${stacks} ${this.type} to ${this.owner.title}.`);
      }
    }
  }

  halveStacks(){
    this.removeStacks(Math.ceil(this.stacks/2));
  }

  removeStacks(stacks: number){
    this.stacks -= stacks;
    if (actionHistory) {
      actionHistory.push(`Removed ${stacks} ${this.type} from ${this.owner.title}.`);
    }

    if(this.stacks <= 0){
      this.remove();
    }
  }

  remove(){
    const index = this.owner.statusEffects.indexOf(this);
    this.owner.statusEffects.splice(index, 1);
  }

  /*getOwner(){
    if(this.owner == 'Player'){
      return playerInstance;
    }
    else{
      return enemyInstance;
    }
  }*/
}