<svelte:options accessors={true}/>

<script lang='ts'>
  import DieIcon from './die-icon.svelte';
  import Dietip from './die-tip.svelte';
  import DieTicks from './die-ticks.svelte';
  import { shortclick } from '../../actions';
  import { DieFace } from '../models/face.type';

  let clazz = '';
  let element: HTMLElement;
  export { clazz as class };
  export let die;
  export let index;
  export let clickEvent = (die:any) => {};
  export let die3D = true;
  export let hover = true;
  let animationDelay = Math.random()*0.2;
  export let sizeMod = 0;

  export let state = 'idle';
  function clickEventLocal(index){
    if(state == 'idle'){
      clickEvent(die);
    }
  }

  let rotX = 0;
  let rotY = 0;
  let count = Math.round(Math.random())*2-1;
  let mouseover = false;
  setRotation();

  setTimeout(()=>{
    if(element){
      element.addEventListener('mouseover', ()=>{
        mouseover = hover;
      });
      element.addEventListener('mouseleave', ()=>{
        mouseover = false;
      });
    }
  }, 500);

  export function roll(): Promise<boolean> {
    return new Promise((accept, reject) => {
      if(die.locked){
        accept(undefined);
        return;
      }
      element.classList.remove("rolling");
      element.offsetWidth;
      element.classList.add("rolling");
      state = 'rolling';

      die.activeFace = Math.floor(Math.random()*6);

      setTimeout(()=>{
        state = 'idle';

        // if face is broken, accept as locked
        if(die.value()[0] === DieFace.Broken){
          accept(false);
        }

        accept(true);
      }, 900);

      setRotation();
    });
  }

  function setRotation(){
    switch(die.activeFace){
      case 5:
        rotX = 0;
        rotY = 0;
        break;
      case 4:
        rotX = 0;
        rotY = 2;
        break;
      case 3:
        rotX = 0;
        rotY = 1;
        break;
      case 2:
        rotX = 0;
        rotY = 3;
        break;
      case 1:
        rotX = 3;
        rotY = 0;
        break;
      case 0:
        rotX = 1;
        rotY = 0;
        break;
    }

    rotX *= 90;
    rotY *= 90;

    rotX += 360*count;
    rotY += 360*count;
    count += Math.round(Math.random())*2-1;
  }

</script>

<!-- <Dietip die={die}> -->
    <!-- {die.locked ? "hidden" : ""} -->
<div
  class='
    die
    die-{die.index}
    die-level-{die.level}
    {die.colour}
    {die.selected ? "selected" : ""}
    {die.used? "used" : ""}
    {clazz || ""}
    {die.material || ""}
    size-{sizeMod}
  '
  style='animation-delay: {animationDelay}s'
  on:click={() => {clickEventLocal(index)}}
  on:keydown={() => {}}
  bind:this={element}
>
  {#if die3D == true}
    <div class='die-3d' style="transform: rotateX({rotX}deg) rotateY({rotY}deg)">
      <div class="face front">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[5]} sizeMod={sizeMod}/>
      </div>
      <div class="face back">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[4]} sizeMod={sizeMod}/>
      </div>
      <div class="face left">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[3]} sizeMod={sizeMod}/>
      </div>
      <div class="face right">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[2]} sizeMod={sizeMod}/>
      </div>
      <div class="face top">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[1]} sizeMod={sizeMod}/>
      </div>
      <div class="face bottom">
        <DieTicks level={die.level}></DieTicks>
        <DieIcon face={die.faces[0]} sizeMod={sizeMod}/>
      </div>
    </div>
  {/if}

  {#if die3D != true}
    <div class='die-2d face'>
      <DieTicks level={die.level}></DieTicks>
      <DieIcon face={die.value()} sizeMod={sizeMod}/>
    </div>
  {/if}
</div>

<Dietip die={die} mouseover={mouseover}/>

<style lang='less'>
  .die{
    width: var(--dice-size);
    height: var(--dice-size);
    transition: opacity 0.3s, filter 0.3s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation-duration: 1s;
    /*perspective: 1000px;*/
    /*perspective-origin: 50% 100%;*/

    &.size-2{
      width: calc(var(--dice-size) / 2);
      height: calc(var(--dice-size) / 2);
    }
    &.size-1{
      width: calc(var(--dice-size) / 1.5);
      height: calc(var(--dice-size) / 1.5);
    }

    &.rolling {
      animation-name: bounce;
    }
  }

  .die-2d{
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
  }

  .die-3d{
    width: var(--dice-size);
    height: var(--dice-size);
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
    will-change: transform;

    .face{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: var(--dice-size);
      height: var(--dice-size);
      border-radius: 4px;
      margin: 0 auto;
    }

    .front {
      transform: translateZ(calc(var(--dice-size) / 2));
    }

    .back {
      transform: rotateX(-180deg) rotateZ(180deg) translateZ(calc(var(--dice-size) / 2));
    }

    .right {
      transform: rotateY(90deg) translateZ(calc(var(--dice-size) / 2));
    }

    .left {
      transform: rotateY(-90deg) translateZ(calc(var(--dice-size) / 2));
    }

    .top {
      transform: rotateX(90deg) translateZ(calc(var(--dice-size) / 2));
    }

    .bottom {
      transform: rotateX(-90deg) translateZ(calc(var(--dice-size) / 2));
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: scale(1);}
    15% {-webkit-transform: scale(1.3);}
    40% {-webkit-transform: scale(1.2);}
    60% {-webkit-transform: scale(1.1);}
  }
</style>
