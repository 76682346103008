import type { traitDefinition } from '../trait.type';
import { type Player, dealDamage, addModifier, healthRemove } from "../player.type";
import { DieMaterial, DieType, invokeDie } from "../die.type";
import { Trait } from '../trait.type';
import type { Technique } from '../techniques.type';
import { randomInt } from '../../utils/math';
import type { Die, DieCreation } from '../die.type';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';
import type { Damage } from '../damage.type';

export const boss: Array<traitDefinition> = [
  {
    type: 'phoenix',
    title: 'Phoenix ashes',
    text: 'At the start of your turn, gain rebirth up to half your current health.',
    icon: 'trait',
    class: class extends Trait {
      startTurn(): void {
        const player = this.owner;
        const health = player.healthCurrent;
        const rebirth = player.statusEffects.find(modifier => {
          return modifier.type == 'rebirth';
        });

        if (rebirth) {
          return;
        }
        const amount = Math.floor(health / 2);
        addModifier(player, StatusEffect.Rebirth, amount);
      }
    }
  }, {
    type: 'cerberus',
    title: 'Cerberus Bite',
    text: 'Gain access to the quick bite skill.',
    icon: 'trait',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        return [{
          title: 'Quick Bite',
          text: 'Deal 1 Damage.',
          tags: ['instant', 'damage'],
          ingredients: [DieFace.Bite],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            dealDamage({value: 1, target, source, dice});
          }
        }];
      }
    }
  }, {
    type: 'warden',
    title: 'Warden\'s Wrath',
    text: 'At the start of every even turn, gain 1 Strength. At the end of every odd turn, gain 1 Armoured .',
    icon: 'trait',
    class: class extends Trait {
      turn = 0;
      startTurn(): void {
        if (this.turn % 2 === 0) {
          addModifier(this.owner, StatusEffect.Strength, 1);
        } else {
          addModifier(this.owner, StatusEffect.Armoured, 1);
        }
        this.turn++;
      }
    }
  }, {
    type: 'corestone-guardian',
    title: 'Corestone Guardian',
    text: 'At the start of each round, gain Imbue 1 of a random elemental effect.',
    icon: 'trait',
    class: class extends Trait {
      startTurn(): void {
        const elements: Array<StatusEffect> = [StatusEffect.Burning, StatusEffect.Freezing, StatusEffect.Spark, StatusEffect.Brittle];
        addModifier(this.owner, StatusEffect.Imbue, 1, { imbue: elements[randomInt(0, 3)] });
      }
    }
  }, {
    type: 'kraken',
    title: 'Kraken',
    text: 'When using an attack, if the target is grappled deal 1 pure damage per die used.',
    icon: 'trait',
    class: class extends Trait {
      useTechnique(technique: Technique, target: Player, source: Player, die: Array<Die>): void {
        if (target.statusEffects.some(modifier => modifier.type === StatusEffect.Grappled)) {
          healthRemove({ value: die.length, target, source, dice: [] });
        }
      }
    }
  }, {
    type: 'many-heads',
    title: 'Many Heads Makes Fight Work',
    text: 'When taking 4 or more damage, gain 1 extra head. At the start of your turn, gain 1 die for each head.',
    icon: 'trait',
    class: class extends Trait {
      init() {
        super.init();
        this.stacks = 0;
      }

      damageIn(damage: Damage): void {
        if (damage.value >= 4) {
          this.stacks++;
        }
      }

      getDice(dice: Array<Die>): Array<DieCreation> {
        let array: Array<DieCreation> = [];

        for (let i = 0; i < this.stacks; i++) {
          array.push({
            type: DieType.Maw,
            level: 0,
            material: DieMaterial.Normal
          });
        }

        return array;
      };
    }
  }
];