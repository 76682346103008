import type { traitDefinition } from '../trait.type';
import { type Player, dealDamage, addModifier } from "../player.type";
import { invokeDie } from "../die.type";
import { Trait } from '../trait.type';
import type { Technique } from '../techniques.type';
import { randomInt } from '../../utils/math';
import type { Die } from '../die.type';
import {note} from '../techniques/note';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';

export const regular: Array<traitDefinition> = [
  {
    type: 'musical',
    title: 'Musical',
    text: '',
    icon: '',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        return note;
      }
    }
  }, {
    type: 'non-musical',
    title: 'Non-musical',
    text: '',
    icon: '',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        let index = techniques.findIndex(technique => technique.tags.includes('song'));
        while(index !== -1){
          techniques.splice(index, 1);
          index = techniques.findIndex(technique => technique.tags.includes('song'));
        }
        return [];
      }
    }
  }, {
    type: 'alluring',
    title: 'Alluring',
    text: '',
    icon: '',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        // remove a technique with the title 'Rubato'
        let index = techniques.findIndex(technique => technique.title === 'Rubato');
        techniques.splice(index, 1);
        return [];
      }
    }
  }, {
    type: 'brawler',
    title: 'Brawler',
    text: 'Gives access to the Heavy Punch Technique.',
    icon: 'trait',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        return [{
          ingredients: [DieFace.Fist],
          title: 'Heavy Punch',
          text: 'Deal 1 damage, gain double bonus from Strength.',
          tags: ['damage'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            let strength = source.statusEffects.find(modifier => {
              return modifier.type == 'strength';
            });
            if (strength) {
              dealDamage({ value: 1 + strength.stacks, target, source, dice });
            }
            else {
              dealDamage({ value: 1, target, source, dice });
            }
          }
        }];
      }
    }
  }, {
    type: 'swarm',
    title: 'Swarm',
    text: 'On the start of turn, set Powerful for every 10% current HP higher than 50% max HP, or set Weakness for every 10% current HP lower than 50% max HP.',
    icon: 'trait',
    class: class extends Trait {
      beforeStartTurn() {
        const hpPercent = this.owner.healthCurrent / this.owner.healthMax;
        let powerful = this.owner.statusEffects.find(modifier => {
          return modifier.type == 'powerful';
        });
        let weakness = this.owner.statusEffects.find(modifier => {
          return modifier.type == 'weakness';
        });

        if (hpPercent > 0.5) {
          // if has modifier powerful, set stacks to 0
          if (powerful) {
            powerful.stacks = Math.ceil((hpPercent - 0.5) * 10);
          }
          else {
            addModifier(this.owner, StatusEffect.Powerful, Math.ceil((hpPercent - 0.5) * 10));
          }
          if (weakness) {
            weakness.removeStacks(weakness.stacks);
          }
        }
        else {
          // if has modifier weakness, set stacks to 0
          if (weakness) {
            weakness.stacks = Math.ceil((0.5 - hpPercent) * 10);
          }
          else {
            addModifier(this.owner, StatusEffect.Weakness, Math.ceil((0.5 - hpPercent) * 10));
          }
          if (powerful) {
            powerful.removeStacks(powerful.stacks);
          }
        }
      }
    }
  }, {
    type: 'beholder',
    title: 'Beholder\'s Glare',
    text: 'Gives access to the Beholder\'s Glare Technique.',
    icon: 'trait',
    class: class extends Trait {
      getTechniques(techniques: Technique[]): Technique[] {
        return [{
          ingredients: [DieFace.Eye, DieFace.Brain],
          title: 'Beholder\'s Glare',
          text: 'Apply 1 Clumsy, 1 Dopey, and 1 Distracted to target.',
          tags: ['debuff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            addModifier(target, StatusEffect.Clumsy, 1);
            addModifier(target, StatusEffect.Dopey, 1);
            addModifier(target, StatusEffect.Distracted, 1);
          }
        }];
      }
    }
  }
];