import type { itemDefinition } from '../item.type';
import { type Player, healDamage, hasItem, removeItem, addModifier } from "../player.type";
import { DieMaterial, DieType, type Die } from "../die.type";
import { Item, Items } from '../item.type';
import type { DieCreation } from '../die.type';

export const dies: Array<itemDefinition> = [
  {
    type: Items.CorestoneFragmentFire,
    title: 'Corestone Fragment (fire)',
    text: 'Gain lvl.-1 Fire die.',
    icon: 'corestone',
    bonusIcon: 'fire',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.Fire,
          level: -1,
          material: DieMaterial.Normal
        }];
      }
    }
  }, {
    type: Items.CorestoneFragmentLightning,
    title: 'Corestone Fragment (lightning)',
    text: 'Gain lvl.-1 Lightning die.',
    icon: 'corestone',
    bonusIcon: 'lightning',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.Lightning,
          level: -1,
          material: DieMaterial.Normal
        }]
      }
    }
  }, {
    type: Items.CorestoneFragmentIce,
    title: 'Corestone Fragment (ice)',
    text: 'Gain lvl.-1 Ice die.',
    icon: 'corestone',
    bonusIcon: 'ice',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.Ice,
          level: -1,
          material: DieMaterial.Normal
        }]
      }
    }
  }, {
    type: Items.CorestoneFragmentEarth,
    title: 'Corestone Fragment (earth)',
    text: 'Gain lvl.-1 Earth die.',
    icon: 'corestone',
    bonusIcon: 'earth',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.Earth,
          level: -1,
          material: DieMaterial.Normal
        }]
      }
    }
  }, {
    type: Items.RustyShiv,
    title: 'Rusty Shiv',
    text: 'Gain lvl.-1 Shiv die.',
    icon: 'shiv',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.Shivs,
          level: -1,
          material: DieMaterial.Normal
        }]
      }
    }
  }, {
    type: Items.RustyCoin,
    title: 'Rusty Coin',
    text: 'Gain lvl.1 Lucky Coin die.',
    icon: 'coin',
    class: class extends Item {
      getDice(): Array<DieCreation> {
        return [{
          type: DieType.LuckyCoin,
          level: 1,
          material: DieMaterial.Normal
        }]
      }
    }

  }
];