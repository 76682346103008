import { clone } from '../utils/utils';
import { DieMaterial, DieType } from './die.type';
import { EnvironmentType } from './environment.type';
import { createItem } from './items';
import type { Player, PlayerDefinition } from './player.type';
import { createTrait } from './traits';

export type EnemyDefinition = {
  title: string;
  text: string;
  type: string;
  healthMax: number;
  rerollMax?: number;
  colour?: string;
  dice?: Array<DieType>;

  traits?: Array<string>;
  inventory?: Array<string>;

  environments: Array<EnvironmentType>;
  strengths?: Array<string>;
  weaknesses?: Array<string>;
}

export function getEnemyDefinition(type: string) {
  let enemy = enemies.find(enemy => enemy.type == type);
  if(!enemy){
    enemy = bosses.find(enemy => enemy.type == type);
  }
  if(!enemy){
    enemy = special.find(enemy => enemy.type == type);
  }
  return enemy;
}

export function createEnemyInstance(level: number, enemy?: EnemyDefinition): Player {
  if (!enemy){
    enemy = enemies[Math.floor(Math.random()*enemies.length)];
  }

  var instance: Player = clone(enemy);

  instance.rerollMax = 2;
  instance.inventory = [];
  instance.statusEffects = [];
  instance.traits = [];
  instance.type = enemy.type;
  instance.id = 'enemy';
  instance.level = level;
  instance.healthCurrent = instance.healthMax;
  instance.dice = enemy.dice.map(type => {
    return {
      type,
      level: 0,
      material: DieMaterial.Normal
    }
  });

  if (enemy.traits && enemy.traits.length) {
    instance.traits = enemy.traits.map(type => {
      const trait = createTrait(instance, type);
      trait.owner = instance;
      return trait;
    });
  }
  if (enemy.inventory && enemy.inventory.length) {
    instance.inventory = enemy.inventory.map(type => {
      const item = createItem(type);
      item.owner = instance;
      return item;
    });
  }

  for(let i = 0; i < level; i++){
    levelUp(instance);
  }
  instance.healthCurrent = instance.healthMax;

  return instance;
}

function levelUp(enemy: Player){
  enemy.healthMax += 2;

  let index = Math.floor(Math.random()*enemy.dice.length);
  let die = enemy.dice[index];
  let levelNum = die.level;

  if(levelNum == 2){
    enemy.dice[index].level = 0;
    enemy.dice.push(clone(enemy.dice[index]));
  }
  else{
    enemy.dice[index].level ++;
  }
}

export const enemies: Array<EnemyDefinition> = [
  {
    title: 'Minotaur',
    text: 'A mythical creature with the body of a man and the head of a bull, known for its immense strength and ferocity.',
    type: 'minotaur',
    healthMax: 10,
    dice: [DieType.Axe,DieType.Axe,DieType.Hoof,DieType.Hoof,DieType.Horn,DieType.Horn],
    strengths: ['High damage attacks', 'Ability to stun'],
    weaknesses: ['Self stun on heavy attacks'],
    environments: [EnvironmentType.Mountain, EnvironmentType.Cave]
  },{
    title: 'Wolf',
    text: 'A carnivorous mammal known for its powerful jaws, sharp teeth, and strong legs, which allow it to hunt and kill prey with great efficiency.',
    type: 'wolf',
    healthMax: 10,
    dice: [DieType.Bite,DieType.Bite,DieType.Paw,DieType.Paw,DieType.Reflex,DieType.Reflex],
    strengths: ['Balanced Attacks', 'High dodging'],
    weaknesses: ['Vulnerable to snare'],
    traits: ['non-musical'],
    environments: [EnvironmentType.Forest, EnvironmentType.Plains]
  }, {
    title: 'Vampire',
    text: 'A supernatural being that survives by drinking the blood of the living.',
    type: 'vampire',
    healthMax: 10,
    dice: [DieType.Fangs,DieType.Fangs,DieType.Cloak,DieType.Cloak,DieType.Speed,DieType.Speed],
    strengths: ['Lifestealing attacks', 'High dodging'],
    weaknesses: ['Cannot lifesteal through block'],
    environments: [EnvironmentType.Cave, EnvironmentType.TownAlley]
  },{
    title: 'Ghoul',
    text: 'A supernatural being with a decaying appearance and a ravenous appetite for the flesh of the living.',
    type: 'ghoul',
    healthMax: 10,
    dice: [DieType.Fangs,DieType.Fangs,DieType.Claws,DieType.Claws,DieType.Bite,DieType.Bite],
    strengths: ['Lifestealing attacks', 'High damage output'],
    weaknesses: ['Cannot lifesteal through block', 'Lacks defensive options'],
    environments: [EnvironmentType.Cave, EnvironmentType.Forest]
  },{
    title: 'Troll',
    text: 'A large, humanoid monster with superhuman strength and regenerative abilities.',
    type: 'troll',
    healthMax: 10,
    dice: [DieType.Hammer,DieType.Hammer,DieType.Strength,DieType.Strength,DieType.Resilience,DieType.Resilience],
    environments: [EnvironmentType.Mountain, EnvironmentType.Cave]
  },{
    title: 'Orc Warrior',
    text: 'A heavily armed and armoured orc who combines brute strength with tactical prowess in battle.',
    type: 'orc-warrior',
    healthMax: 10,
    dice: [DieType.Axe,DieType.Axe,DieType.Shield,DieType.Shield,DieType.Blood,DieType.Blood],
    environments: [EnvironmentType.Mountain, EnvironmentType.Plains]
  }, {
    title: 'Orc Beserker',
    text: 'A lightly armoured orc wielding a powerful axe, entering a trance-like state of pure rage to unleash devastating attacks on foes.',
    type: 'orc-beserker',
    healthMax: 10,
    dice: [DieType.Axe, DieType.Axe, DieType.Strength, DieType.Strength, DieType.Blood, DieType.Blood],
    environments: [EnvironmentType.Mountain, EnvironmentType.Plains]
  }, {
    title: 'Orc Lancer',
    text: 'An orc adept in spear combat, utilizing a long spear and strategic battle tactics to pierce through enemy lines.',
    type: 'orc-lancer',
    healthMax: 10,
    dice: [DieType.Spear, DieType.Spear, DieType.Strength, DieType.Strength, DieType.Blood, DieType.Blood],
    environments: [EnvironmentType.Mountain, EnvironmentType.Plains]
  }, {
    title: 'Orc Brawler',
    text: 'An unarmed orc specialist who eschews weapons in favor of powerful fist-fighting, preferring close-quarters combat in the heat of battle.',
    type: 'orc-brawler',
    healthMax: 10,
    traits: ['brawler'],
    dice: [DieType.Bracer, DieType.Bracer, DieType.Strength, DieType.Strength, DieType.Blood, DieType.Blood],
    environments: [EnvironmentType.Mountain, EnvironmentType.Plains]
  }, {
    title: 'Goblin Scrapper',
    text: 'A small, cunning fighter who specializes in using dirty tricks and surprise attacks to defeat larger and stronger opponents.',
    type: 'goblin-scrapper',
    healthMax: 10,
    dice: [DieType.Shortsword,DieType.Shortsword,DieType.Reflex,DieType.Reflex,DieType.Poison,DieType.Poison],
    environments: [EnvironmentType.Forest, EnvironmentType.Swamp]
  }, {
    title: 'Goblin Archer',
    text: 'A small, stealthy fighter specialized in using a bow and a quiver full of magical arrows to attack from a distance.',
    type: 'goblin-archer',
    healthMax: 10,
    dice: [DieType.Bow,DieType.Bow,DieType.AmmoBag,DieType.AmmoBag,DieType.Reflex,DieType.Reflex],
    environments: [EnvironmentType.Forest, EnvironmentType.Swamp]
  }, {
    title: 'Goblin Assassin',
    text: 'A small, stealthy fighter who specialized in using deadly poisons and surprise attacks to take out their targets.',
    type: 'goblin-assassin',
    healthMax: 10,
    dice: [DieType.Dagger,DieType.Dagger, DieType.Poison,DieType.Poison, DieType.Cloak, DieType.Cloak],
    environments: [EnvironmentType.Forest, EnvironmentType.Swamp]
  }, {
    title: 'Crow',
    text: 'A mythic avian entity with obsidian feathers and razor-sharp talons, renowned for its ominous presence and relentless aerial supremacy.',
    type: 'crow',
    healthMax: 10,
    dice: [DieType.Claws, DieType.Claws, DieType.Beak, DieType.Beak, DieType.Wings, DieType.Wings],
    traits: ['non-musical'],
    environments: [EnvironmentType.Plains, EnvironmentType.Forest]
  }, {
    title: 'Crab',
    text: 'A legendary beast with the body of a crustacean and the pincers of a titan, revered for its monstrous size and merciless might.',
    type: 'crab',
    healthMax: 10,
    dice: [DieType.Pincer, DieType.Pincer, DieType.Shell, DieType.Shell, DieType.Strength, DieType.Strength],
    environments: [EnvironmentType.Coast, EnvironmentType.Lake]
  }, {
    title: 'Scorpion',
    text: 'A massive arachnid of nightmarish proportions, with a venomous stinger and armored exoskeleton, feared for its deadly precision and ruthless aggression.',
    type: 'scorpion',
    healthMax: 10,
    dice: [DieType.Pincer, DieType.Pincer, DieType.Shell, DieType.Shell, DieType.Stinger, DieType.Stinger],
    environments: [EnvironmentType.Desert, EnvironmentType.Mountain]
  }, {
    title: 'Spider',
    text: 'A massive arachnid with venomous fangs and the ability to spin webs to capture prey.',
    type: 'spider',
    healthMax: 10,
    dice: [DieType.Web, DieType.Web, DieType.Poison, DieType.Poison, DieType.Stinger, DieType.Stinger],
    environments: [EnvironmentType.Forest, EnvironmentType.Cave]
  }, {
    title: 'Wasp',
    text: 'A massive insect with venomous stingers that can be used to attack multiple times.',
    type: 'wasp',
    healthMax: 10,
    dice: [DieType.Wings, DieType.Wings, DieType.Poison, DieType.Poison, DieType.Stinger, DieType.Stinger],
    strengths: ['Poison Damage', 'Quick, multi-attacks', 'High dodging'],
    weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.Plains, EnvironmentType.Forest]
  }, {
    title: 'Fire Elemental',
    text: 'A blazing entity, forged by the essence of the Corestone, wields the unyielding might of flames, engulfing all in its path with an inferno of power.',
    type: 'elemental-fire',
    healthMax: 10,
    traits: ['elemental-affinity-fire', 'elemental-synergy'],
    dice: [DieType.Fire, DieType.Fire, DieType.Wand, DieType.Wand, DieType.Mana, DieType.Mana],
    environments: [EnvironmentType.Mountain, EnvironmentType.Desert]
  }, {
    title: 'Ice Elemental',
    text: 'Embodying the chilling magic of the Corestone, the Ice Elemental commands the frigid winds, freezing adversaries in their tracks with an icy breath.',
    type: 'elemental-ice',
    healthMax: 10,
    traits: ['elemental-affinity-ice', 'elemental-synergy'],
    dice: [DieType.Ice, DieType.Ice, DieType.Tome, DieType.Tome, DieType.Mana, DieType.Mana],
    environments: [EnvironmentType.River, EnvironmentType.Lake]
  }, {
    title: 'Lightning Elemental',
    text: 'Crackling with electric energy infused by the Corestone, the Lightning Elemental harnesses the primal force of thunder, striking foes with electrifying precision.',
    type: 'elemental-lightning',
    healthMax: 10,
    traits: ['elemental-affinity-lightning', 'elemental-synergy'],
    dice: [DieType.Lightning, DieType.Lightning, DieType.Staff, DieType.Staff, DieType.Mana, DieType.Mana],
    environments: [EnvironmentType.Plains, EnvironmentType.Mountain]
  }, {
    title: 'Earth Elemental',
    text: 'Born of the Corestone\'s grounding power, the Earth Elemental wields the strength of solid ground, summoning quaking tremors to shape the battlefield in their favour.',
    type: 'elemental-earth',
    healthMax: 10,
    traits: ['elemental-affinity-earth', 'elemental-synergy'],
    dice: [DieType.Earth, DieType.Earth, DieType.Crystal, DieType.Crystal, DieType.Mana, DieType.Mana],
    environments: [EnvironmentType.Swamp, EnvironmentType.Mountain]
  }, {
    title: 'Bandit Brawler',
    text: 'A fast and lightly armored bandit brawler, relying on swift movements and bare-knuckle strikes to overpower adversaries in lightning-fast combat.',
    type: 'bandit-brawler',
    healthMax: 10,
    traits: ['brawler'],
    dice: [DieType.Bracer, DieType.Bracer, DieType.Strength, DieType.Strength, DieType.Reflex, DieType.Reflex],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Bandit Ranger',
    text: 'A cunning bandit skilled in archery, expertly ambushing unsuspecting victims and using their bow to ward off intruders from their concealed lair.',
    type: 'bandit-ranger',
    healthMax: 10,
    traits: [],
    dice: [DieType.Bow, DieType.Bow, DieType.AmmoBag, DieType.AmmoBag, DieType.Reflex, DieType.Reflex],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Bandit Collector',
    text: 'A formidable enforcer and strong-arm bully within the bandit ranks, dispatched to collect debts with an imposing presence.',
    type: 'bandit-collector',
    healthMax: 10,
    traits: [],
    dice: [DieType.Club, DieType.Club, DieType.Cloak, DieType.Cloak, DieType.Reflex, DieType.Reflex],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Bandit Thug',
    text: 'A frontline enforcer guarding the bandit camp, this thug specializes in brutal ambush tactics to deter and eliminate threats.',
    type: 'bandit-thug',
    healthMax: 10,
    traits: [],
    dice: [DieType.Longsword, DieType.Longsword, DieType.Shield, DieType.Shield, DieType.Strength, DieType.Strength],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Bandit Rogue',
    text: 'A master of guile and stealth, the rogue bandit employs cunning and sneakiness to pilfer valuables from unsuspecting targets.',
    type: 'bandit-rogue',
    healthMax: 10,
    traits: [],
    dice: [DieType.Dagger, DieType.Dagger, DieType.Cloak, DieType.Cloak, DieType.Reflex, DieType.Reflex],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Bandit Trapper',
    text: 'A strategic bandit specializing in the creation and maintenance of traps, using cunning devices to capture intruders and safeguard the bandit camp.',
    type: 'bandit-trapper',
    healthMax: 10,
    traits: [],
    dice: [DieType.Spear, DieType.Spear, DieType.Cloak, DieType.Cloak, DieType.Reflex, DieType.Reflex],
    environments: [EnvironmentType.TownAlley, EnvironmentType.TownBarracks]
  }, {
    title: 'Harpy',
    text: 'A bird-like creature with a hypnotic song, drawing unsuspecting travelers closer before revealing its true intention to feast upon them.',
    type: 'harpy',
    healthMax: 10,
    dice: [DieType.Beak, DieType.Beak, DieType.Wings, DieType.Wings, DieType.Rhythmic, DieType.Rhythmic],
    traits: ['alluring'],
    environments: [EnvironmentType.Plains, EnvironmentType.Mountain]
  }, {
    title: 'Siren',
    text: 'A captivating mermaid whose haunting melodies enchant sailors, luring them into treacherous waters where they meet their watery demise.',
    type: 'siren',
    healthMax: 10,
    dice: [DieType.Claws, DieType.Claws, DieType.Shell, DieType.Shell, DieType.Rhythmic, DieType.Rhythmic],
    traits: ['alluring'],
    environments: [EnvironmentType.Lake, EnvironmentType.River, EnvironmentType.Coast]
  }, {
    title: 'Wasp Swarm',
    text: 'A swarm of wasps, working together to overwhelm and incapacitate their prey.',
    type: 'wasp-swarm',
    healthMax: 10,
    dice: [DieType.Wings, DieType.Wings, DieType.Poison, DieType.Poison, DieType.Stinger, DieType.Stinger],
    traits: ['swarm'],
    // strengths: ['Poison Damage', 'Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.Plains, EnvironmentType.Forest]
  }, {
    title: 'Rat Plague',
    text: 'A swarm of rats, working together to overwhelm and incapacitate their prey.',
    type: 'rat-plague',
    healthMax: 10,
    dice: [DieType.Claws, DieType.Claws, DieType.Bite, DieType.Bite, DieType.Speed, DieType.Speed],
    traits: ['swarm'],
    // strengths: ['Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.TownAlley]
  }, {
    title: 'Water Devils',
    text: 'A swarm of water spirits, working together to overwhelm and incapacitate their prey.',
    type: 'water-devils',
    healthMax: 10,
    dice: [DieType.Fangs, DieType.Fangs, DieType.Tentacle, DieType.Tentacle, DieType.Stealth, DieType.Stealth],
    traits: ['swarm'],
    // strengths: ['Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.River, EnvironmentType.Lake, EnvironmentType.Coast]
  }, {
    title: 'Snake',
    text: '',
    type: 'snake',
    healthMax: 10,
    dice: [DieType.Maw, DieType.Maw, DieType.Poison, DieType.Poison, DieType.Tail, DieType.Tail],
    traits: [],
    // strengths: ['Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.River, EnvironmentType.Lake, EnvironmentType.Coast]
  }, {
    title: 'Sand Wyrm',
    text: '',
    type: 'sand-wyrm',
    healthMax: 10,
    dice: [DieType.Maw, DieType.Maw, DieType.Resilience, DieType.Resilience, DieType.Tail, DieType.Tail],
    traits: [],
    // strengths: ['Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.River, EnvironmentType.Lake, EnvironmentType.Coast]
  }, {
    title: 'Beholder',
    text: '',
    type: 'beholder',
    healthMax: 10,
    dice: [DieType.Maw, DieType.Maw, DieType.Reflex, DieType.Reflex, DieType.Intelligence, DieType.Intelligence],
    traits: ['beholder'],
    // strengths: ['Quick, multi-attacks', 'High dodging'],
    // weaknesses: ['Low damage output', 'Low sustain defence', 'Vulnerable to snare'],
    environments: [EnvironmentType.Cave, EnvironmentType.Mountain]
  }, {
    title: 'Giant',
    text: '',
    type: 'giant',
    healthMax: 10,
    dice: [DieType.Hammer, DieType.Hammer, DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
    environments: [EnvironmentType.Cave, EnvironmentType.Mountain, EnvironmentType.Plains]
  }, {
    title: 'Manticore',
    text: '',
    type: 'manticore',
    healthMax: 10,
    dice: [DieType.Wings, DieType.Paw, DieType.Bite, DieType.Stinger, DieType.Reflex, DieType.Poison],
    environments: [EnvironmentType.Cave, EnvironmentType.Mountain, EnvironmentType.Plains]
  },/* {
    title: 'Skeleton',
    text: 'A reanimated corpse, brought to life by the Corestone\'s magic, with a body of bone and a heart of darkness.',
    type: 'skeleton',
    healthMax: 10,
    dice: ['bone', 'bone', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, *//*  {
    title: 'Zombie',
    text: 'A reanimated corpse, brought to life by the Corestone\'s magic, with a body of flesh and a heart of darkness.',
    type: 'zombie',
    healthMax: 10,
    dice: ['flesh', 'flesh', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, *//*  {
    title: 'Ghost',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'ghost',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, */ /*  */ /* {
    title: 'Demon',
    text: 'A supernatural entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'demon',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, */ /* {
    title: 'Imp',
    text: 'A supernatural entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'imp',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, */ /* {
    title: 'Succubus',
    text: 'A supernatural entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'succubus',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  } */

  /*
  {
    title: 'Wraith',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'wraith',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Claws, DieType.Claws, DieType.Cloak, DieType.Cloak],
  }, {
    title: 'Banshee',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'banshee',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Rhythmic, DieType.Rhythmic],
  }, {
    title: 'Spectre',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'wraith',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Intelligence, DieType.Intelligence, 'arcane', 'arcane'],
  }
  */
];

export const bosses: Array<EnemyDefinition> = [
  {
    title: 'Phoenix',
    text: 'A mythical and legendary fire bird, able to rise anew from its ashes, unleashing fiery devastation upon those who dare to challenge its flame.',
    type: 'phoenix',
    healthMax: 10,
    traits: ['phoenix', 'non-musical'],
    dice: [DieType.Beak, DieType.Beak, DieType.Wings, DieType.Wings, DieType.Fire, DieType.Fire],
    // reward phoenix feather - gain 5 rebirth. if ever reborn, destroy item
    environments: [EnvironmentType.Mountain, EnvironmentType.Desert]
  },

  /* {
    title: 'Dragon',
    text: '',
    type: 'dragon',
    healthMax: 10,
    dice: [DieType.Fire, DieType.Fire, DieType.Claws, DieType.Claws, DieType.Wings, DieType.Wings],
  }, */ /*  {
    title: 'Hydra',
    text: '',
    type: 'hydra',
    healthMax: 10,
    dice: [DieType.Claws, DieType.Claws, DieType.Poison, DieType.Poison, DieType.Fire, DieType.Fire],
  }, */
  {
    title: 'Warden',
    text: 'Magically crafted protectors with unwavering focus, devoted to defending their mark with relentless determination and formidable magical abilities.',
    type: 'warden',
    healthMax: 10,
    traits: ['warden'],
    dice: [DieType.Longsword, DieType.Longsword, DieType.Shield, DieType.Shield, DieType.Strength, DieType.Strength],
    //reward, armour shard
    environments: [EnvironmentType.TownBarracks, EnvironmentType.CorestoneChamber]
  }, {
    title: 'Kraken',
    text: 'A diminutive yet formidable sea monster, the baby kraken possesses enough power to crush a man, hinting at the colossal terror it may become as it grows.',
    type: 'kraken',
    healthMax: 10,
    dice: [DieType.Tentacle, DieType.Tentacle, DieType.Bite, DieType.Bite, DieType.Intelligence, DieType.Intelligence],
    traits: ['kraken'],
    //reward, kraken ink
    environments: [EnvironmentType.Coast, EnvironmentType.Lake]
  }, {
    title: 'Cerberus',
    text: 'A fearsome three-headed dog born of fire, guarding the underworld with ferocity and the power of its infernal triple gaze.',
    type: 'cerberus',
    healthMax: 10,
    dice: [DieType.Bite, DieType.Bite, DieType.Fire, DieType.Fire, DieType.Strength, DieType.Strength],
    traits: ['cerberus'],
    //reward cerberus puppy
    environments: [EnvironmentType.Mountain, EnvironmentType.Cave]
  }, {
    title: 'Corestone Guardian',
    text: 'A mysterious being formed from the elemental essence of the corestone, its motives shrouded in mystery as it wields the formidable powers of the earth, air, fire, and water.',
    type: 'corestone-guardian',
    healthMax: 10,
    dice: [DieType.Fire, DieType.Fire, DieType.Ice, DieType.Ice, DieType.Lightning, DieType.Lightning, DieType.Earth, DieType.Earth],
    traits: ['corestone-guardian', 'elemental-synergy'],
    environments: [EnvironmentType.ElementalNexus, EnvironmentType.CorestoneChamber]
    // reward, corestone combiner (gain lighting+fire, earth+ice recipes)
  }, {
    title: 'Hydra',
    text: '',
    type: 'hydra',
    healthMax: 10,
    dice: [DieType.Maw, DieType.Maw, DieType.Resilience, DieType.Resilience, DieType.Tail, DieType.Tail],
    traits: ['many-heads'],
    environments: [EnvironmentType.Mountain, EnvironmentType.Cave]
    // reward, corestone combiner (gain lighting+fire, earth+ice recipes)
  }, /* {
    title: 'Giant',
    text: '',
    type: 'giant',
    healthMax: 10,
    dice: [DieType.Hammer, DieType.Hammer, DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  },  {
    title: 'Skeleton',
    text: 'A reanimated corpse, brought to life by the Corestone\'s magic, with a body of bone and a heart of darkness.',
    type: 'skeleton',
    healthMax: 10,
    dice: ['bone', 'bone', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  },  {
    title: 'Zombie',
    text: 'A reanimated corpse, brought to life by the Corestone\'s magic, with a body of flesh and a heart of darkness.',
    type: 'zombie',
    healthMax: 10,
    dice: ['flesh', 'flesh', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, {
    title: 'Ghost',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'ghost',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, {
    title: 'Wraith',
    text: 'A spectral entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'wraith',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, {
    title: 'Demon',
    text: 'A supernatural entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'demon',
    healthMax: 10,
    dice: ['shadow', 'shadow', DieType.Resilience, DieType.Resilience, DieType.Strength, DieType.Strength],
  }, {
    title: 'Imp',
    text: 'A supernatural entity, born of the Corestone\'s magic, with a body of shadow and a heart of darkness.',
    type: 'imp',
    healthMax:10
  } */
];

export const special: Array<EnemyDefinition> = [
  {
    title: 'Training Dummy',
    text: 'A lifeless target molded from straw and cloth, providing a stoic canvas for warriors to hone their combat skills and perfect their techniques.',
    type: 'training-dummy',
    healthMax: 10,
    environments: [EnvironmentType.TownArena],
    dice: [DieType.Shield, DieType.Shield, DieType.Resilience, DieType.Resilience, DieType.Blank, DieType.Blank],
  }, {
    title: 'Bomb Seller',
    text: 'An eccentric merchant specializing in explosive wares, offering a variety of bombs and volatile devices to adventurous souls seeking a more explosive approach to problem-solving.',
    type: 'salesman-bomb',
    healthMax: 10,
    dice: [DieType.Shortsword, DieType.Shortsword, DieType.Reflex, DieType.Reflex, DieType.Cloak, DieType.Cloak, DieType.LuckyCoin, DieType.LuckyCoin],
    inventory: ['smokebomb', 'firebomb'],
    environments: [EnvironmentType.Forest, EnvironmentType.Plains]
  }, {
    title: 'Potion Seller',
    text: 'A charismatic vendor peddling a colorful array of potions, elixirs, and concoctions, ready to aid adventurers with magical brews to enhance their abilities.',
    type: 'salesman-potion',
    healthMax: 10,
    dice: [DieType.Wand, DieType.Wand, DieType.Circlet, DieType.Circlet, DieType.Intelligence, DieType.Intelligence, DieType.LuckyCoin, DieType.LuckyCoin],
    inventory: ['healthpotion', 'manapotion', 'claritypotion'],
    environments: [EnvironmentType.Forest, EnvironmentType.Plains]
  }
];
