import agility from "./icons/agility.svg?raw";
import sword from "./icons/sword.svg?raw";
import fist from "./icons/fist.svg?raw";
import bone from "./icons/bone.svg?raw";
import shield from "./icons/shield.svg?raw";
import lock from "./icons/lock.svg?raw";
import undo from "./icons/undo.svg?raw";
import redo from "./icons/redo.svg?raw";
import dagger from "./icons/dagger.svg?raw";
import cloak from "./icons/mask.svg?raw";
import dodge from "./icons/dodge.svg?raw";
import axe from "./icons/axe.svg?raw";
import horn from "./icons/horn.svg?raw";
import hoof from "./icons/hoof.svg?raw";
import blood from "./icons/blood.svg?raw";
import dizzy from "./icons/dizzy.svg?raw";
import regeneration from "./icons/regeneration.svg?raw";
import armoured from "./icons/armoured.svg?raw";
import eye from "./icons/eye.svg?raw";
import accuracy from "./icons/accuracy.svg?raw";
import poison from "./icons/poison.svg?raw";
import blessed from "./icons/blessed.svg?raw";
import cursed from "./icons/cursed.svg?raw";
import stinger from "./icons/stinger.svg?raw";
import pincer from "./icons/pincer.svg?raw";
import bite from "./icons/bite.svg?raw";
import wings from "./icons/wings.svg?raw";
import wildcard from "./icons/wildcard.svg?raw";
import strength from "./icons/strength.svg?raw";
import weakness from "./icons/weakness.svg?raw";
import broken from "./icons/broken.svg?raw";
import swap from "./icons/swap.svg?raw";
import fire from "./icons/fire.svg?raw";
import ice from "./icons/ice.svg?raw";
import lightning from "./icons/lightning.svg?raw";
import stun from "./icons/stun.svg?raw";
import brain from "./icons/brain.svg?raw";
import ammo from "./icons/ammo.svg?raw";
import fight from "./icons/fight.svg?raw";
import mana from "./icons/mana.svg?raw";
import stealth from "./icons/stealth.svg?raw";
import claws from "./icons/claws.svg?raw";
import blind from "./icons/blind.svg?raw";
import web from "./icons/web.svg?raw";
import blank from "./icons/blank.svg?raw";
import prehensile from "./icons/prehensile.svg?raw";
import twincast from "./icons/twincast.svg?raw";
import ward from "./icons/ward.svg?raw";
import grave from "./icons/grave.svg?raw";
import hammer from "./icons/hammer.svg?raw";
import reroll from "./icons/reroll.svg?raw";
import glossary from "./icons/glossary.svg?raw";
import options from "./icons/options.svg?raw";
import quiver from "./icons/quiver.svg?raw";
import bow from "./icons/bow.svg?raw";
import infusion from "./icons/infusion.svg?raw";
import no from "./icons/no.svg?raw";
import nextarrow from "./icons/next-arrow.svg?raw";
import prevarrow from "./icons/prev-arrow.svg?raw";
import wound from "./icons/wound.svg?raw";
import rested from "./icons/rested.svg?raw";
import clash from "./icons/clash.svg?raw";
import dart from "./icons/dart.svg?raw";
import shiv from "./icons/shiv.svg?raw";
import spear from "./icons/spear.svg?raw";
import beak from "./icons/beak.svg?raw";
import shell from "./icons/shell.svg?raw";
import vulnerable from "./icons/vulnerable.svg?raw";
import reflex from "./icons/reflex.svg?raw";
import brittle from "./icons/brittle.svg?raw";
import earth from "./icons/earth.svg?raw";
import imbue from "./icons/imbue.svg?raw";
import focus from "./icons/focus.svg?raw";
import barrier from "./icons/barrier.svg?raw";
import trait from "./icons/trait.svg?raw";
import stance from "./icons/stance.svg?raw";
import spikes from "./icons/spikes.svg?raw";
import slow from "./icons/slow.svg?raw";
import crossbow from "./icons/crossbow.svg?raw";
import blowgun from "./icons/blowgun.svg?raw";
import note from "./icons/note.svg?raw";
import clef from "./icons/clef.svg?raw";
import distracted from "./icons/distracted.svg?raw";
import silver from "./icons/silver.svg?raw";
import delay from "./icons/delay.svg?raw";
import ethereal from "./icons/ethereal.svg?raw";
import petrified from "./icons/petrified.svg?raw";
import grappled from "./icons/grappled.svg?raw";
import clumsy from "./icons/clumsy.svg?raw";

import backpack from "./icons/backpack.svg?raw";
import potionsmall from "./icons/potion-small.svg?raw";
import corestone from "./icons/corestone.svg?raw";
import coin from "./icons/coin.svg?raw";
import bomb from "./icons/bomb.svg?raw";

import battle0 from "./map-icons/enemy0.svg?raw";
import battle1 from "./map-icons/enemy1.svg?raw";
import battle2 from "./map-icons/enemy2.svg?raw";
import battle3 from "./map-icons/enemy3.svg?raw";
import battle4 from "./map-icons/enemy4.svg?raw";
import battle5 from "./map-icons/enemy5.svg?raw";
import battle6 from "./map-icons/enemy6.svg?raw";
// import boss0 from "./map-icons/boss0.svg?raw";
import boss1 from "./map-icons/boss1.svg?raw";
import boss2 from "./map-icons/boss2.svg?raw";
import boss3 from "./map-icons/boss3.svg?raw";
import boss4 from "./map-icons/boss4.svg?raw";
import campfire from "./map-icons/campfire.svg?raw";
import scout from "./map-icons/scout.svg?raw";
import event from "./map-icons/event.svg?raw";
import quest from "./map-icons/quest.svg?raw";
import town from "./map-icons/town.svg?raw";
import scouted from "./map-icons/scouted.svg?raw";

export const icons = {
  sword: sword,
  fist: fist,
  bone: bone,
  shield: shield,
  blank: blank,
  ['']: blank,
  lock: lock,
  undo: undo,
  redo: redo,
  agility: agility,
  dagger: dagger,
  cloak: cloak,
  dodge: dodge,
  axe: axe,
  horn: horn,
  hoof: hoof,
  blood: blood,
  dizzy: dizzy,
  regeneration: regeneration,
  armoured: armoured,
  eye: eye,
  accuracy: accuracy,
  poison: poison,
  poisoned: poison,
  blessed: blessed,
  cursed: cursed,
  stinger: stinger,
  pincer: pincer,
  bite: bite,
  wings: wings,
  wildcard: wildcard,
  strength: strength,
  weakness: weakness,
  broken: broken,
  swap: swap,
  fire: fire,
  ice: ice,
  lightning: lightning,
  stun: stun,
  brain: brain,
  ammo: ammo,
  fight: fight,
  mana: mana,
  stealth: stealth,
  claws: claws,
  blind: blind,
  web: web,
  prehensile: prehensile,
  twincast: twincast,
  ward: ward,
  grave: grave,
  hammer: hammer,
  reroll: reroll,
  glossary: glossary,
  options: options,
  quiver: quiver,
  bow: bow,
  infusion: infusion,
  no: no,
  nextarrow: nextarrow,
  prevarrow: prevarrow,
  wound: wound,
  rested: rested,
  clash: clash,
  dart: dart,
  shiv: shiv,
  spear: spear,
  beak: beak,
  shell: shell,
  vulnerable: vulnerable,
  reflex: reflex,
  brittle: brittle,
  earth: earth,
  imbue: imbue,
  focus: focus,
  barrier: barrier,
  trait: trait,
  stance: stance,
  spikes: spikes,
  slow: slow,
  crossbow: crossbow,
  blowgun: blowgun,
  note: note,
  song: clef,
  distracted: distracted,
  delay: delay,
  ethereal: ethereal,
  petrified,
  grappled,
  clumsy,

  // ammo icons
  silver: silver,
  // poison: poison,
  serrated: blood,
  blinding: blind,
  crippling: web,
  burning: fire,
  heavy: fist,
  basic: blank,

  backpack: backpack,
  coin: coin,
  potionsmall: potionsmall,
  corestone: corestone,
  bomb: bomb,

  battle0: battle0,
  battle1: battle1,
  battle2: battle2,
  battle3: battle3,
  battle4: battle4,
  battle5: battle5,
  battle6: battle6,
  battle7: battle6,
  battle8: battle6,
  battle9: battle6,
  // boss0: boss0,
  boss1: boss1,
  boss2: boss2,
  boss3: boss3,
  boss4: boss4,
  campfire: campfire,
  scout: scout,
  event: event,
  quest: quest,
  town: town,
  scouted: scouted,
};

Object.keys(icons).map(key => {
  icons[key] = {
    icon: icons[key],
    svg: icons[key].substring(icons[key].indexOf("<g>") + 3, icons[key].lastIndexOf("</g>"))
  };
});

const iconCache = {};

export function createSVGUrl(svg) {
  var svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
  return URL.createObjectURL(svgBlob);
};

function insertProperty(svg: string, property: string, value: string){
  let start = svg.indexOf("<g>");
  let end = svg.lastIndexOf("</g>");
  return svg.substring(0, start) + `<g ${property}="${value}">` + svg.substring(start, end) + '</g>' + svg.substring(end);
}

export function getIcon(title: string, properties?: { [key: string]: string }){
  let propstring = JSON.stringify(properties);
  if (iconCache[title+propstring]) {
    return iconCache[title+propstring];
  }
  if (!icons[title]){
    console.error(`No icon with title: ${title}`);
  }

  let svg = icons[title].icon;
  if (properties){
    for(let property in properties){
      svg = insertProperty(svg, property, properties[property]);
    }
  }

  const output = createSVGUrl(svg);
  iconCache[title+propstring] = output;
  return output;
}

export function mergeIcons(title1: string, title2: string){
  if (iconCache[title1+title2]){
    return iconCache[title1+title2];
  }
  if (!icons[title1]) {
    console.error(`No icon with title: ${title1}`);
  }
  if (!icons[title2]) {
    console.error(`No icon with title: ${title2}`);
  }
  const svg1 = icons[title1].svg;
  const svg2 = icons[title2].svg;
  const intro = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1"
	 id="Layer_1" xmlns:sodipodi="http://sodipodi.source1rge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" inkscape:version="1.2 (dc2aedaf03, 2022-05-15)" sodipodi:docname="enemy1.svg"
	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="500px" height="500px"
	 viewBox="0 0 500 500" enable-background="new 0 0 500 500" xml:space="preserve">`;
  const svg = intro + svg1 + svg2 + '</svg>';
  const output = createSVGUrl(svg);
  iconCache[title1+title2] = output;
  return output;
}