import { dealDamage, addModifier, healthRemove } from '../player.type';
import type { Technique } from "../techniques.type";
import type { Player } from '../player.type';
import type { Die } from '../die.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const prehensile: Technique[] = [
  {
    ingredients: [DieFace.Prehensile],
    title: 'Slap',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Prehensile, DieFace.Prehensile],
    title: 'Grab',
    text: 'Deal 1 Damage. Apply 2 Grappled',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Grappled, 2);
    }
  }, {
    ingredients: [DieFace.Prehensile, DieFace.Prehensile, DieFace.Prehensile],
    title: 'Sieze',
    text: 'Deal 2 Damage. Apply 4 Grappled',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Grappled, 4);
    }
  }, {
    ingredients: [DieFace.Prehensile, DieFace.Web],
    title: 'Wrap',
    text: 'Apply 1 Snared and 1 Grappled',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Grappled, 1);
      addModifier(target, StatusEffect.Snared, 1);
    }
  }, {
    ingredients: [DieFace.Prehensile, DieFace.Bite],
    title: 'Munch',
    text: 'If target is grappled, gain 1 accuracy. Deal 2 damage.',
    tags: ['damage', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      if (target.statusEffects.some(modifier => modifier.type === StatusEffect.Grappled)) {
        addModifier(source, StatusEffect.Accuracy, 1);
      }
      dealDamage({ value: 2, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Prehensile, DieFace.Bone],
    title: 'Guard',
    text: 'Gain 2 block and 1 Imbue (Grappled).',
    tags: [],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 2);
      addModifier(source, StatusEffect.Imbue, 1, { imbue: StatusEffect.Grappled });
    }
  }/* , {
    ingredients: [DieFace.Prehensile, DieFace.Reflex],
    title: 'Strike',
    text: 'Deal 2 pure damage.',
    tags: [],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      healthRemove({ value: 2, target, source, dice });
    }
  } */
];
