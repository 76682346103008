<script lang="ts">
  import { screens, stateNode, playerTruth, playerInstance, enemyInstance, glossary } from '../store';
  import { setupEnvironment } from "./models/environment.type";
  import { createEnemyInstance } from './models/enemy.type';
  import { clonePlayer, type Player } from './models/player.type';
  import Icon from "./icon/icon.svelte";
  import Portrait from "./components/portrait.svelte";
  import { randomInt } from './utils/math';
  import { actionHistory } from '../store';
  import { track } from './utils/playerTracking';
  import Frame from './components/frame.svelte';
  import Chrome from './components/chrome.svelte';

  export let data;

  const player = clonePlayer($playerTruth);
  player.statusEffects.sort((a, b) => {
    return b.priority - a.priority;
  });

  const enemy: Player = createEnemyInstance(data.battle.level, data.battle.enemy);

  console.log(data.battle)

  if(data.battle.environment){
    setupEnvironment(data.battle.environment);
  }
  else if(data.battle.enemy.environments && data.battle.enemy.environments.length){
    const environment: string = data.battle.enemy.environments[randomInt(0, data.battle.enemy.environments.length - 1)];
    setupEnvironment(environment);
    /* data.battle.enemy.environments.forEach(env => {
      setupEnvironment(env);
    }); */
  }
  else{
    setupEnvironment('forest');
  }

  function start(){
    //create player instances
    screens.set('battle', {battle: data.battle, player: player, enemy: enemy});

    actionHistory.length = 0;
  }

  function viewManual(){
    glossary.open.set(true);
    glossary.selected.set('enemies');
    glossary.searchTerm.set(enemy.title);
  }

  track('Battle Start', {
    player_class: $playerTruth.type,
    player_archetype: $playerTruth.archetype,
    player_level: $playerTruth.level,

    enemy_class: data.battle.enemy.type,
    enemy_level: data.battle.enemy.level
  });
</script>

<div class='background {data.battle.environment}'>
</div>

<Frame>
  <div class='container'>
    <h1>Prepare to Fight!</h1>

    <div class='vs'>
      <div>
        level: {enemy.level+1}
      </div>

      <div class='portrait-container enemy'>
        <h2>{enemy.title}</h2>
        <Portrait data={enemy}/>
      </div>

      <Chrome click={viewManual} edge='circle' thickness='thin' chroming='obsidian'>
        <div class='icon'><Icon name='glossary' width='50px'/></div>
      </Chrome>
    </div>

    <div class='description'>
      <h2>{enemy.text}</h2>
    </div>

    <div class='margin-auto'>
      <Chrome click={start} edge='circle' chroming='gold'>
        <div class='button'>Fight</div>
      </Chrome>
    </div>
  </div>
</Frame>

<style lang='less'>
  @import "../defines.less";

  :root{
    background-color: var(--background-colour);
  }

  .background{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    filter: blur(5px);
    z-index: 0;
    box-shadow: inset 0 0 40px 10px rgba(0, 0, 0, 1);
  }

  .container{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    margin: auto;
    position: relative;

    .button{
      font-size: 32px;
      padding: 4px 20px;
      color: #ffffd5;
    }

    .vs{
      display: flex;
      align-items: end;
      justify-content: center;
      margin: auto;
      flex-direction: row;
      grid-gap: 20px;

      h2{
        margin-bottom: 10px;
      }
      .icon{
        height: 50px;
        width: 50px;
        --icon-size: 50px;
        padding: 10px;
      }

      .portrait-container{
        padding: 0 10px;
      }
    }

    .description{
      max-width: min(80vw, 800px);
      margin: auto;
    }

    .margin-auto{
      margin: auto;
    }
  }

  :global(.battle-icon){
    --icon-size: 200px;
  }
  @media @medium{
    :global(.battle-icon){
      --icon-size: 150px;
    }
    .container{
      grid-gap: 25px;
      .vs{
        max-width: 600px;
        grid-gap: 10px;
      }
    }
  }
  @media @small{
    :global(.battle-icon){
      --icon-size: 75px;
    }
    .container{
      .vs{
        max-width: 500px;
        grid-gap: 5px;
      }
    }
  }
</style>