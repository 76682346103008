import { dealDamage, healDamage, addModifier } from './player.type';
import { getStore, activePlayer, inactivePlayer } from '../../store';
import type { Die } from './die.type';
import { bow } from "./techniques/bow";
import { hammer } from "./techniques/hammer";
import { sword } from "./techniques/sword";
import { axe } from "./techniques/axe";
import { stinger } from "./techniques/stinger";
import { dagger } from "./techniques/dagger";
import { pincer } from "./techniques/pincer";
import { claws } from "./techniques/claws";
import { horn } from "./techniques/horn";
import { beak } from "./techniques/beak";
import { bite } from "./techniques/bite";
import { basics } from "./techniques/basics";
import { shield } from "./techniques/shield";
import { shell } from "./techniques/shell";
import { fire } from "./techniques/fire";
import { lightning } from "./techniques/lightning";
import { ice } from "./techniques/ice";
import { earth } from "./techniques/earth";
import { instant } from "./techniques/instant";
import { ammo } from "./techniques/ammo";
import { spear } from "./techniques/spear";
import { blowgun } from "./techniques/blowgun";
import { crossbow } from "./techniques/crossbow";
import { note } from "./techniques/note";
import { prehensile } from "./techniques/prehensile";
// import { mana } from "./techniques/mana";
import type { Player } from './player.type';
import { StatusEffect } from './status-effects';
import { DieFace } from './face.type';

var techniquesIn = [];

export type Requirement = {
  text: string;
  icon?: string;
  iconBonus?: string;
  test: Function;
};
export type Technique = {
  ingredients: Array<DieFace>;
  requirements?: Requirement[];
  title: string;
  text: string;
  activate: Function;
  selected?: Array<Die|string>;
  valid?: boolean;
  hidden?: boolean;
  tags: Array<string>
};

export function filterTechniques(lockedDie: Array<Die>, techniquesIn?: Array<Technique>){
  var selecteds = lockedDie.filter(die => die.selected && !die.used);
  var spares = lockedDie.filter(die => !die.selected && !die.used);
  var used = lockedDie.filter(die => die.used);

  // var currentTechnique = selecteds.map(selected => selected.faces[selected.value]);

  if(techniquesIn === undefined){
    techniquesIn = techniques;
  }

  // Finds valid techniques based on selected die
  let validTechniques = availableTechniques([...selecteds, ...spares, ...used], techniquesIn);
  validTechniques.forEach(technique => {
    technique.hidden = false;
  });
  if (selecteds.length){
    validTechniques = possibleTechniques(selecteds, [...spares, ...used], validTechniques);
  }

  return validTechniques;
}

export function validateTechnique(technique: Technique, lockedDie: Array<Die>){
  var selecteds = lockedDie.filter(die => die.selected && !die.used);
  var spares = lockedDie.filter(die => !die.selected && !die.used);
  technique.valid = !!isTechniqueValid(selecteds, spares, technique);

  const dieList = createDieList(selecteds);
  const spareList = createDieList(spares);

  technique.selected = [];
  technique.ingredients.forEach((face: DieFace, index: number) => {
    let dice = findDie(dieList, face);
    if (dice.length) {
      const die = dice[0];
      technique.selected[index] = die.die;
      die.faces[face].count--;
    }
    else {
      let dice = findDie(spareList, face);
      if (dice.length) {
        const die = dice[0];
        technique.selected[index] = 'found';
        die.faces[face].count--;
      }
    }
  });
}

export function availableTechniques(dice: Array<Die>, techniquesIn?: Array<Technique>) {
  let types = [];
  dice.forEach((die: Die) => {
    die.faces.forEach((face: Array<DieFace>) => {
      if (!face || !face.filter){
        console.log(dice, die, face);
      }
      var asd = face.filter(face => face !== DieFace.OR);
      asd.forEach(dsa => {
        if (types.indexOf(dsa) == -1) {
          types.push(dsa);
        }
      })
    });
  });

  const process = techniquesIn || techniques;
  return process.filter(technique => {
    let keep = technique.ingredients.every(ingredient => {
      if (types.indexOf(ingredient) !== -1) {
        return true;
      }
    });

    return keep;
  });
}

// filters all techniques to show ones that use the required die
export function possibleTechniques(required: Array<Die>, spare: Array<Die>, techniquesIn?: Array<Technique>) {
  const process = techniquesIn || techniques;
  return process.map(technique => {
    const dieList = createDieList(required);
    let keep = false;

    technique.ingredients.forEach((face: DieFace, index: number) => {
      let dice = findDie(dieList, face);
      if (dice.length) {
        const die = dice[0];
        die.faces[face].count --;
        die.used.push(true);
      }

      if (dieList.filter(die => {
        return die.used.length == 0;
      }).length == 0) {
        keep = true;
      }
    });

    technique.hidden = !keep;
    // return keep;
    return technique;
  });
}

function createDieList(die: Array<Die>): Array<DieListItem>{
  return die.map(die => {
    // if the die is an OR die, shares a counter object
    if (die.value().indexOf(DieFace.OR) !== -1) {
      const counter = {
        count: 1
      };
      let obj = {};
      for (let face of die.value().filter(face => face !== DieFace.OR)){
        obj[face] = counter;
      }

      return {
        die: die,
        faces: obj,
        used: []
      };
    }

    // if the die is an AND die or single face die, has a counter object for each face
    let obj = {};
    for (let face of die.value()) {
      if(obj[face]){
        obj[face].count ++;
      }
      else{
        obj[face] = { count: 1 };
      }
    }
    return {
      die: die,
      faces: obj, // object that contains the count of each face type on the die
      used: []
    };
  });
}

type DieListItem = {
  die: Die;
  faces: object;
  used: Array<boolean>;
}

function findDie(dieList: Array<DieListItem>, key: DieFace): Array<DieListItem>{
  if(key === DieFace.Blank){
    return dieList.sort((a, b) => {
      // if value exactly matches die, sort it first
      if (a.die.value()[0] == key) {
        return -1;
      }
      if (b.die.value()[0] == key) {
        return 1;
      }
      return 0;
    })
    .map(die => {
      for (let keyy of Object.keys(die.faces)){
        die.faces[key] = {count: die.die.value()[0] === '' ? 1 : 0};
        if (die.faces[keyy].count > die.faces[key].count){
          die.faces[key] = die.faces[keyy];
        }
      }
      return die;
    })
    .filter(die => {
      if(die.faces['broken']){
        return false;
      }
      //iterate through die.faces array to find the total count value
      return Object.keys(die.faces).reduce((total, face) => {
        return total + die.faces[face].count;
      }, 0);
    });
  }

  return dieList.filter((die) => {
    return (die.faces[key] && die.faces[key].count > 0) || (die.faces['wildcard'] && die.faces['wildcard'].count > 0);
  })
  .map(die => {
    if(die.faces['wildcard'] && !die.faces[key]){
      die.faces[key] = die.faces['wildcard'];
    }
    return die;
  });
}

export function isTechniqueValid(required: Array<Die>, spare: Array<Die>, technique: Technique) {
  // Convert ingredients to a dictionary
  const dieList = createDieList(required);
  const spareList = createDieList(spare);

  // Helper function to recursively generate all possible combinations of the recipe
  function checkCombination(index: number) {
    if (index === technique.ingredients.length) {
      return true; // All elements of the technique have been assigned a value
    }

    const key = technique.ingredients[index];
    // required die
    let dice = findDie(dieList, key as DieFace);
    // spare die if required there is not a matching required
    if(!dice.length){
      dice = findDie(spareList, key as DieFace);
    }

    for(const die of dice){
      die.faces[key].count --;
      die.used.push(true);
      if (checkCombination(index + 1)) {
        return true;
      }
      die.faces[key].count ++;
      die.used.pop();
    }

    return false;
  }

  // Start recursion from index 0
  var result = checkCombination(0);

  // makes sure all required die are used at least once
  if (dieList.filter(die => {
    return die.used.length == 0;
  }).length > 0){
    return false;
  }

  if(result){
    // converts result into a list of the used die
    const passed = dieList.map(die => {
      return die.die;
    })
    .concat(spareList.filter(die => {
      return die.used.length > 0;
    })
    .map(die => {
      return die.die;
    }));

    if(!passed.length && technique.ingredients.length > 0){
      return false;
    }

    // checks if the technique has any requirements
    let test = true;
    if (technique.requirements) {
      for (let requirement of technique.requirements) {
        if (!requirement.test(getStore(activePlayer), getStore(inactivePlayer))) {
          test = false;
        }
      }
    }
    if(!test){
      return false;
    }

    return passed;
  }

  return false;
}

export function filterTechniquesByTag(tag:string) {
  return techniques.filter(technique => {
    return technique.tags.includes(tag);
  });
}


export const techniques: Technique[] = [
  {
    ingredients: [DieFace.Cloak,DieFace.Cloak],
    title: 'Hide',
    text: 'Gain 1 Stealth.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stealth, 1);
    }
  },{
    ingredients: [DieFace.Cloak,DieFace.Cloak,DieFace.Cloak],
    title: 'Vanish',
    text: 'Gain 2 Stealth and 1 Dodge.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 1);
      addModifier(source, StatusEffect.Stealth, 2);
    }
  },{
    ingredients: [DieFace.Hoof,DieFace.Hoof],
    title: 'Buck',
    text: 'Apply 1 Stunned.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Stunned, 1);
    }
  },{
    ingredients: [DieFace.Fist, DieFace.Poison],
    title: 'Throw Poison',
    text: 'Deal 1 Damage, Apply 1 Poisoned.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({value: 1, target, source, dice});
      addModifier(target, StatusEffect.Poison, 1);
    }
  },{
    ingredients: [DieFace.Poison, DieFace.Poison],
    title: 'Imbue Poison',
    text: 'Gain 2 Imbue (poisoned).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Imbue, 2, { imbue: StatusEffect.Poison});
    }
  },{
    ingredients: [DieFace.Eye,DieFace.Cloak],
    title: 'Stalk Pray',
    text: 'Gain 1 Stealth and 1 Accuracy.',
    tags: ['defence', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stealth, 1);
      addModifier(source, StatusEffect.Accuracy, 1);
    }
  },{
    ingredients: [DieFace.Agility,DieFace.Cloak],
    title: 'Escape',
    text: 'Gain 1 Stealth and 1 Dodge.',
    tags: ['defence', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stealth, 1);
      addModifier(source, StatusEffect.Dodge, 1);
    }
  },

  {
    ingredients: [DieFace.Wings],
    title: 'Flap',
    text: 'Gain 1 Dodge.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 1);
    }
  },{
    ingredients: [DieFace.Wings, DieFace.Wings],
    title: 'Flip-Flap',
    text: 'Gain 3 dodge.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 3);
    }
  },/* {
    ingredients: ['wings', 'wings', 'wings'],
    title: 'Fly',
    text: 'Gain 1 Immortal',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      // addModifier(source, 'immortal', 1);
    }
  }, */



  {
    ingredients: [DieFace.Web],
    title: 'Capture',
    text: 'Apply 1 Snared.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Snared, 1);
    }
  },{
    ingredients: [DieFace.Web, DieFace.Web],
    title: 'Entangle',
    text: 'Apply 3 Snared.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Snared, 3);
    }
  },{
    ingredients: [DieFace.Web, DieFace.Web, DieFace.Web],
    title: 'Constrict', //strangled
    text: 'Apply 5 Snared.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Snared, 5);
    }
  }, {
    ingredients: [DieFace.Web, DieFace.Poison],
    title: 'Venomous Web',
    text: 'Apply 2 Snared and 2 Poisoned.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Snared, 2);
      addModifier(target, StatusEffect.Poison, 2);
    }
  }, {
    ingredients: [DieFace.Web, DieFace.Fist],
    title: 'Throw Net',
    text: 'If the target has no Snared, add 3 Snared. Otherwise, add 2 Vulnerable.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const snared = target.statusEffects.find(modifier => {
        return modifier.type == StatusEffect.Snared;
      });
      if (snared) {
        addModifier(target, StatusEffect.Vulnerable, 2);
      }
      else {
        addModifier(target, StatusEffect.Snared, 3);
      }
    }
  },/* {
    ingredients: ['web', 'agility'],
    title: 'Sticky Web',
    text: 'Add 2 Snared and 1 Slowed.',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, 'snared', 2);
      addModifier(source, 'slowed', 1);
    }
  }, */
//@ts-ignore
].concat(bow).concat(hammer).concat(sword).concat(axe).concat(stinger).concat(dagger).concat(shell)
//@ts-ignore
.concat(pincer).concat(claws).concat(horn).concat(beak).concat(bite).concat(basics).concat(shield).concat(fire)
//@ts-ignore
.concat(ice).concat(lightning).concat(earth).concat(instant).concat(ammo).concat(spear)/* .concat(mana) */
//@ts-ignore
.concat(blowgun).concat(crossbow).concat(note).concat(prehensile);


techniques.map(technique => {
  technique.selected = [];
  return technique;
})
.sort((a, b) => {
  const x = a.ingredients.join(DieFace.Blank);
  const y = b.ingredients.join('');
  if (x < y) {
    return -1;
  }
  if (x > y) {
    return 1;
  }
  return 0;
});


const parseTechniques = () => {
  const titles = [];
  techniques.forEach(technique => {
    //check for duplicate technique titles
    if (titles.includes(technique.title)) {
      console.error('Duplicate technique title: ' + technique.title, technique);
    }
    titles.push(technique.title);
  });
}
parseTechniques();
