import { writable } from 'svelte/store';
import type { Writable } from 'svelte/store';
import type { Die } from '../models/die.type';
import type { AmmoType } from '../models/techniques/ammo';
import type { Player } from '../models/player.type';
import type { Item } from '../models/item.type';

//modals
export const alertData: Writable<AlertData|null> = writable(null);
export const confirmData: Writable<ConfirmData|null> = writable(null);
export const promptData: Writable<PromptData|null> = writable(null);
export const codeData: Writable<AlertData|null> = writable(null);
export const selectDieData: Writable<DiceData|null> = writable(null);
export const selectFaceData: Writable<DieData|null> = writable(null);
export const optionData: Writable<OptionData|null> = writable(null);
export const ammoData: Writable<AmmoData|null> = writable(null);
export const inventoryData: Writable<InventoryData|null> = writable(null);
export const tutorialData: Writable<TutorialData|null> = writable(null);

type AlertData = {
  title: string,
  text: string,
  buttonText?: string,
  callback?: Function
};

type ConfirmData = {
  title: string,
  text: string,
  callbackConfirm?: Function
  callbackCancel?: Function
};

type PromptData = {
  title: string,
  text: string,
  callbackConfirm?: Function
  callbackCancel?: Function
};

type DiceData = {
  dice: Array<Die>,
  callback?: Function
};

type DieData = {
  die: Die,
  callback?: Function
};

type OptionData = {
  callback?: Function
};

type AmmoData = {
  callback?: Function,
  ammo: Array<AmmoType>
};

type InventoryData = {
  callback?: Function,
  player: Player;
  inventory: Array<Item>
};

export type TutorialData = {
  callback?: Function,
  title: string,
  text: string,
  anchor?: {
    selector: string,
    position: 'above' | 'below' | 'left' | 'right' | 'center'
  },
  size?: 'small' | 'medium' | 'large'
};

export const alert = (data: AlertData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    alertData.set(data);
  });
};

export const confirm = (data: ConfirmData) => {
  return new Promise<any>((accept, reject) => {
    data.callbackConfirm = (res: any) => {
      accept(res);
    };
    data.callbackCancel = (res: any) => {
      reject(res);
    };

    confirmData.set(data);
  });
};


export const prompt = (data: PromptData) => {
  return new Promise<any>((accept, reject) => {
    data.callbackConfirm = (res: any) => {
      accept(res);
    };
    data.callbackCancel = (res: any) => {
      reject(res);
    };

    promptData.set(data);
  });
};

export const code = (data: AlertData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    codeData.set(data);
  });
};

export const selectDie = (data: DiceData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    selectDieData.set(data);
  });
};

export const selectFace = (data: DieData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    selectFaceData.set(data);
  });
};

export const openOptions = (data: OptionData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    optionData.set(data);
  });
};

export const openAmmo = (data: AmmoData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    ammoData.set(data);
  });
};

export const openInventory = (data: InventoryData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    inventoryData.set(data);
  });
};

export const openTutorial = (data: TutorialData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    tutorialData.set(data);
  });
};