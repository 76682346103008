import { Modifier } from "./modifier";

export class Trait extends Modifier {

  constructor(traitDefinition: traitDefinition) {
    super();
    this.title = traitDefinition.title;
    this.text = traitDefinition.text;
    this.type = traitDefinition.type;
    this.icon = traitDefinition.icon;
  }
}

export type traitDefinition = {
  type: string;
  title: string;
  text: string;
  icon: string;

  class: typeof Trait;
}
