
// Random int
export function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Random float
export function randomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

// Clamp
export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

// distance
export function distance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) +
    Math.pow(y2 - y1, 2));
}
