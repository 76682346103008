<script lang='ts'>
  import Hover from './hover.svelte';
  import Chrome from './chrome.svelte';
  import type { Player } from '../models/player.type';

  export let data: Player;
  export let reverse = false;
  var notches: Array<string>;

  var level1 = [
    {
      title: 'health',
      value: 0,
      percent: 0,
      level2: [
        {
          title: 'freezing',
          value: 0,
          percent: 0
        },{
          title: 'health',
          value: 0,
          percent: 0
        },{
          title: 'poison',
          value: 0,
          percent: 0
        }
      ]
    },{
      title: 'empty',
      value: 0,
      level2: [
        {
          title: 'regen',
          value: 0,
          percent: 0
        },{
          title: 'empty',
          value: 0,
          percent: 0
        }
      ]
    },{
      title: 'over',
      value: 0,
      level2: [
        {
          title: 'block',
          value: 0,
          percent: 0
        },{
          title: 'barrier',
          value: 0,
          percent: 0
        },{
          title: 'armoured',
          value: 0,
          percent: 0
        }
      ]
    }
  ];

  let health = {
    max: 0,
    value: 0,
    percent: 0
  };
  let poison = {
    value: 0,
    percent: 0
  };
  let freezing = {
    value: 0,
    percent: 0
  };
  let block = {
    value: 0,
    percent: 0
  };
  let barrier = {
    value: 0,
    percent: 0
  };
  let armoured = {
    value: 0,
    percent: 0
  };
  let regen = {
    value: 0,
    percent: 0
  };

  function calcNotches(){
    notches = Array(data.healthMax).fill("").map((notch, index) => {
      return data.healthCurrent > index ? "health" : "empty";
    });
    let healthMax = data.healthMax;
    health.max = healthMax;

    data.statusEffects.filter(mod => 'modifyNotches' in mod)
    .forEach(mod => {
      mod.modifyNotches(notches);
    });

    health.value = notches.filter(notch => notch.indexOf('health') >= 0).length;
    health.percent = health.value / healthMax * 100;

    poison.value = notches.filter(notch => notch.indexOf('poison') >= 0).length;
    poison.percent = poison.value / healthMax * 100;

    freezing.value = notches.filter(notch => notch.indexOf('freezing') >= 0).length;
    freezing.percent = freezing.value / healthMax * 100;

    block.value = notches.filter(notch => notch.indexOf('block') >= 0).length;
    block.percent = block.value / healthMax * 100;

    barrier.value = notches.filter(notch => notch.indexOf('barrier') >= 0).length;
    barrier.percent = barrier.value / healthMax * 100;

    armoured.value = notches.filter(notch => notch.indexOf('armoured') >= 0).length;
    armoured.percent = armoured.value / healthMax * 100;

    regen.value = notches.filter(notch => notch.indexOf('regen') >= 0).length;
    regen.percent = regen.value / healthMax * 100;

    /* level1.forEach(level => {
      level.value = notches.filter(notch => notch.includes(level.title)).length;

      level.level2.forEach(level => {
        level.value = notches.filter(notch => notch.includes(level.title)).length;
      });
    });

    level1[2].value = data.healthMax;

    // calculate percentages
    level1.forEach(level1 => {
      level1.percent = level1.value / notches.length * 100;

      level1.level2.forEach(level2 => {
        level2.percent = level2.value / level1.value * 100;
      });
    });

    level1[2].value = notches.length - level1[0].value - level1[1].value;

    console.log(level1) */
  }

  $: if (data !== undefined) {
    calcNotches();
  }
  calcNotches();
</script>

<!-- <Chrome edge='square' thickness='thin' gloss={false} chroming='obsidian' hover={false}> -->
  <div class='health-container {reverse? "reverse" : ""}'>
    <div class='container'>
      <!-- {#each notches as notch, index}
          <div class='notch {notch}'></div>
      {/each} -->
      <div class='bar empty' style='height: 100%;'>
        <div class='bar subbar health' style='height: {health.percent}%;'></div>
        <div class='bar subbar frozen' style='height: {freezing.percent}%;'></div>
        <div class='bar subbar poisoned' style='height: {poison.percent}%;'></div>

        <div class='bar subbar regen' style='height: {regen.percent}%; bottom: {health.percent}%'></div>

        <div class='bar subbar block' style='height: {block.percent}%; bottom: {barrier.percent}%'></div>
        <div class='bar subbar barrier' style='height: {barrier.percent}%;'></div>
      </div>

      <div class='notches'>
        {#each {length: health.max + 1} as _, i}
            <div class='notch'></div>
        {/each}
      </div>

      <!-- <div class='hover-container'>
        <div class='level level1'>
          {#each level1 as level, index}
            <div class='{level.title}' style='flex-grow: {level.value};'>

              <div class='level level2'>
                {#each level.level2 as level2, index}
                  {#if level2.value}
                    <div class='{level2.title}' style='flex-grow: {level2.value};'>
                      {level2.value} {level2.title}
                    </div>
                  {/if}
                {/each}
              </div>
            </div>
          {/each}
        </div>
      </div> -->
    </div>
  </div>
<!-- </Chrome> -->

<style lang='less'>
  @health: #D32F2F;
  @freezing: #0879ad;
  @poison: rgba(56, 142, 60, 1);

  @block: #ddd;
  @barrier: #FBC02D;

  @empty: #000;
  @regeneration: #aa248b;

  .health-container{
    --health: @health;
    --empty: @empty;
    --freezing: @freezing;
    --poison: @poison;
    --block: @block;
    --barrier: @barrier;
    --regeneration: @regeneration;

    display: flex;
    justify-content: end;
    flex-direction: column-reverse;
    grid-gap: 0px;
    transition: grid-gap 0.3s;
    position: relative;
    width: calc(var(--health-bar-width) - 4px);
    height: 100%;
    background: rgba(0,0,0,0.5);
    border: 2px solid black;

    &:hover{
      grid-gap: 10px;

      .container{
        .hover-container{
          pointer-events: all;
          opacity: 1;
          left: var(--health-bar-width);
        }
      }
      &.reverse{
        .container{
          .hover-container{
            right: var(--health-bar-width);
          }
        }
      }
    }

    .container{
      display: flex;
      justify-content: start;
      flex-direction: column-reverse;
      position: relative;
      font-family: 'Roboto Condensed';
      height: 100%;
      width: 100%;

      .bar{
        position: relative;
        width: 100%;
        transition: height 0.3s;
        display: flex;
        flex-direction: column-reverse;

        .subbar{
          position: absolute;
        }

        &.health{
          // background-color: var(--health);
          background-image: linear-gradient(to right, darken(@health, 30%) 0%,  @health 20%, lighten(@health, 20%) 30%, @health 50%, @health 80%, darken(@health, 30%));
          position: absolute;
          bottom: 0;
        }
        &.empty{
          background-image: linear-gradient(to right, darken(@empty, 30%) 0%,  @empty 20%, lighten(@empty, 40%) 30%, @empty 50%, @empty 80%, darken(@empty, 30%));
        }

        &.block{
          background-image: linear-gradient(to right, transparent 30%, @block 70%, darken(@block, 30%));
        }
        &.barrier{
          background-image: linear-gradient(to right, transparent 30%, @barrier 70%, darken(@barrier, 30%));
        }

        &.poisoned{
          background-image: linear-gradient(to right, darken(@poison, 30%) 0%,  @poison 20%, lighten(@poison, 20%) 30%, @poison 50%, @poison 80%, darken(@poison, 30%));
          top: 0;
        }
        &.frozen{
          background-image: linear-gradient(to right, darken(@freezing, 30%) 0%,  @freezing 20%, lighten(@freezing, 20%) 30%, @freezing 50%, @freezing 80%, darken(@freezing, 30%));
        }

        &.regen{
          background-image: linear-gradient(to right, darken(@regeneration, 30%) 0%,  @regeneration 20%, lighten(@regeneration, 20%) 30%, @regeneration 50%, transparent 90%);
        }
      }

      .notches{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .notch{
          width: 75%;
          margin: 0 auto;
          height: 1px;
          background: linear-gradient(to left, transparent, white, transparent);
          // backdrop-filter: brightness(.8);
        }

        .notch:first-child, .notch:last-child{
          background: none;
        }
      }

      .hover-container{
        position: absolute;
        opacity: 0;
        transition: left 0.3s, right 0.3s, opacity 0.3s;
        z-index: 111;
        height: 100%;
        left: 0px;
        display: flex;
        pointer-events: none;

        .level{
          display: flex;
          flex-direction: column-reverse;

          &.level1{
            position: relative;
            width: 20px;
          }

          &.level2{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            right: -100px;
            width: 100px;

            div{
              display: flex;
              align-items: center;
              justify-content: end;
              padding-right: 5px;
            }
          }
          div{
            background: linear-gradient(to right, var(--color1,transparent) 0%, var(--color2,transparent) 20%);
          }

          .health{
            background-color: var(--health);
            position: relative;
            --color1: var(--health);
          }
          .empty{
            background-color: var(--empty);
            position: relative;
            --color1: var(--empty);
          }
          .over{
            position: relative;
          }

          .block{
            background-color: var(--block);
            color: black;
          }
          .poison{
            background-color: var(--poison);
          }
          .armoured{
            background-color: black;
          }
          .barrier{
            background-color: var(--barrier);
            color: black;
          }
          .regen{
            background-color: var(--regeneration);
          }
          .freezing{
            background-color: var(--freezing);
            // color: black;
          }
        }
      }
    }

    &.reverse{
      justify-content: start;
      flex-direction: column;

      .notch{
        background: linear-gradient(210deg, var(--color1, pink) 40%, var(--color2, pink) 60%);
      }

      .container{
        display: flex;
        justify-content: start;
        flex-direction: column;

        .hover-container{
          left: unset;
          right: 0px;

          .level{
            flex-direction: column;

            .level2{
              right: 20px;
              left: -100px;

              div{
                justify-content: start;
                padding-left: 5px;
                background-image: linear-gradient(to left, var(--color1,transparent) 0%, var(--color2,transparent) 20%);
              }
            }
          }
        }
      }
    }

    /* .notch{
      height: 4px;
      border-radius: 4px;
      margin: 2px;
      background: linear-gradient(30deg, var(--color1,pink) 40%, var(--color2,pink) 60%);
      transition: background 0.5s;

      &.health{
        --color1: var(--health);
        --color2: var(--health);
      }
      &.empty{
        --color1: var(--empty);
        --color2: var(--empty);
      }
      &.block{
        --color1: black;
        --color2: black;
      }

      &.poison{
        --color1: var(--poison);
        // --color1: #5e0000;
      }
      &.armoured{
        --color1: var(--empty);
        --color2: var(--block);
      }
      &.barrier{
        --color1: var(--barrier);
        --color2: var(--barrier);
      }
      &.regen{
        --color2: var(--regeneration);
      }
      &.freezing{
        --color1: var(--freezing);
      }
    } */

    .hover{
      position: absolute;
      background: black;
      z-index: 1111;
    }
  }
</style>