<script lang='ts'>
  export let chroming: 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond' | 'obsidian' = 'bronze';
  export let edge: 'square' | 'round' | 'circle' = 'square';
  export let thickness: 'thin' | 'thick' = 'thick';
  export let gloss: boolean = true;
  export let hover: boolean = true;

  export let click: () => void = () => {};
</script>

<div class="chrome {edge} {thickness} {hover? 'hoverable' : ''}" on:click={click} on:keydown={()=>{}}>
  <div class="badge {chroming}">
    <span class="{gloss ? 'glossy':''}"></span>

    <div class='bar-container'>
      <div class='bar'>
      </div>
    </div>
    <div class='inner-bar'>
      <div class='background'></div>
    </div>
  </div>

  <div class="content">
    <slot/>
  </div>
</div>

<style lang='less'>
  .chrome {
    position: relative;
    transition: 300ms filter linear;
    display: flex;
    justify-content: center;
    align-items: center;

    &.round{
      .badge{
        border-radius: 14px;

        .bar-container{
          border-radius: 12px;

          .bar{
            border-radius: 12px;
          }
        }
        .inner-bar{
          border-radius: 10px;

          .background{
            border-radius: 10px;
          }
        }
      }
    }

    &.circle{
      .badge{
        border-radius: 500px;

        .bar-container{
          border-radius: 500px;

          .bar{
            border-radius: 500px;
          }
        }
        .inner-bar{
          border-radius: 500px;

          .background{
            border-radius: 500px;
          }
        }
      }

      .content{
        border-radius: 500px;
        overflow: hidden;
      }
    }

    &.thin{
      .badge{
        .bar-container{
          width: calc(100% - 2px);
          height: calc(100% - 2px);

          .bar{
            left:-5px;
            top: -5px;
            right: -5px;
            bottom: -5px;
          }
        }
        .inner-bar{
          left: 5px;
          top: 5px;
          right: 5px;
          bottom: 5px;

          .background{
            width: calc(100% - 2px);
            height: calc(100% - 2px);
          }
        }
      }

      .content{
        margin: 6px;
        height: calc(100% - 12px);
      }
    }

    &.hoverable{
      &:hover{
        filter: brightness(1.5);

        .glossy{
          background-position: 25%;
        }
      }
    }
  }

  .badge{
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .glossy {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    // background: linear-gradient(to left, rgba(255, 255, 255, 0), white, rgba(255, 255, 255, 0));
    background: radial-gradient(farthest-corner at top, rgba(255,255,255,0.5), rgba(255,255,255,0.15) 10%, rgba(255,255,255,0) 70%);
    z-index: 11;
    background-size: 200% 100%;
    transition: background-position 300ms ease-in-out;
  }

  .badge {
    overflow: hidden;
    width: 100%;
    height: 100%;
    // position: relative;
    flex: 0 auto;
    transition: 500ms transform linear, 300ms filter linear;
    display: flex;
    align-items: center;
    justify-content: center;

    .bar-container{
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .bar{
        position: absolute;
        left:-10px;
        top: -10px;
        right: -10px;
        bottom: -10px;
        filter: blur(3px);
      }
    }

    .inner-bar{
      position: absolute;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .background{
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      box-shadow: inset 0 30px 30px #2f1606;
    }
  }

  .content {
    z-index: 1;
    margin: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 24px);

    & :global(*) {
      z-index: 1;
    }
  }

  .bronze {
    // outline
    background: linear-gradient(5deg, #200f04, #692f1f, #87442b 95%, #fdceb2);

    .bar{
      // chroming
      background-image: conic-gradient(
        #87442b 0deg,

        #87442b 15deg,
        #fdceb2 25deg,
        #87442b 40deg,
        #fdceb2 55deg,
        #692f1f 65deg,

        #2f1606 90deg,
        #2f1606 120deg,
        #692f1f 150deg,
        #87442b 180deg,

        #87442b 195deg,
        #fdceb2 205deg,
        #87442b 220deg,
        #fdceb2 235deg,
        #87442b 245deg,

        #2f1606 270deg,
        #692f1f 300deg,
        #692f1f 330deg,
        #87442b 360deg
      );
    }
    .inner-bar{
      // inline
      background: linear-gradient(185deg, #200f04, #692f1f, #87442b 95%, #fdceb2);
    }
    .background{
      // inner background
      background: linear-gradient(to top, #2f1606, #87442b);
      box-shadow: inset 0 30px 30px #2f1606;
    }
  }

  .silver {
    background: linear-gradient(5deg, #191927, #516262, #838489 95%, #f3f4ff);

    .bar{
      background-image: conic-gradient(
        #838489 0deg,

        #838489 15deg,
        #f3f4ff 25deg,
        #838489 40deg,
        #f3f4ff 55deg,
        #516262 65deg,

        #212134 90deg,
        #212134 120deg,
        #516262 150deg,
        #838489 180deg,

        #838489 195deg,
        #f3f4ff 205deg,
        #838489 220deg,
        #f3f4ff 235deg,
        #838489 245deg,

        #212134 270deg,
        #516262 300deg,
        #516262 330deg,
        #838489 360deg
      );
    }
    .inner-bar{
      background: linear-gradient(185deg, #191927, #516262, #838489 95%, #f3f4ff);
    }
    .background{
      background: linear-gradient(to top, #212134, #838489);
      box-shadow: inset 0 30px 30px #212134;
    }
  }

  .gold {
    background-image: linear-gradient(5deg, #4f2c04, #f16912, #ffd046 95%, #ffffd5);

    .bar{
      background-image: conic-gradient(
        #ffd046 0deg,

        #ffd046 15deg,
        #ffffd5 25deg,
        #ffd046 40deg,
        #ffffd5 55deg,
        #f16912 65deg,

        #4f2c04 90deg,
        #4f2c04 120deg,
        #f16912 150deg,
        #ffd046 180deg,

        #ffd046 195deg,
        #ffffd5 205deg,
        #ffd046 220deg,
        #ffffd5 235deg,
        #ffd046 245deg,

        #4f2c04 270deg,
        #f16912 300deg,
        #f16912 330deg,
        #ffd046 360deg
      );
    }

    .inner-bar{
      background: linear-gradient(185deg, #4f2c04, #f16912, #ffd046 95%, #ffffd5);
    }
    .background{
      background: linear-gradient(to top, #4f2c04, #ffd046);
      box-shadow: inset 0 30px 30px #4f2c04;
    }
  }

  .platinum {
    background: linear-gradient(5deg, #101e42, #51628f, #8384c7 95%, #f3ffff);

    .bar{
      background-image: conic-gradient(
        #8384c7 0deg,

        #8384c7 15deg,
        #f3ffff 25deg,
        #8384c7 40deg,
        #f3ffff 55deg,
        #51628f 65deg,

        #16295b 90deg,
        #16295b 120deg,
        #51628f 150deg,
        #8384c7 180deg,

        #8384c7 195deg,
        #f3ffff 205deg,
        #8384c7 220deg,
        #f3ffff 235deg,
        #8384c7 245deg,

        #16295b 270deg,
        #51628f 300deg,
        #51628f 330deg,
        #8384c7 360deg
      );
    }
    .inner-bar{
      background: linear-gradient(185deg, #101e42, #51628f, #8384c7 95%, #f3ffff);
    }
    .background{
      background: linear-gradient(to top, #16295b, #8384c7);
      box-shadow: inset 0 30px 30px #16295b;
    }
  }

  .diamond {
    background: linear-gradient(5deg, #0c2a8b, #5e52cb, #b4eef2 95%, #12add9);

    .bar{
      background-image: conic-gradient(
        #b4eef2 0deg,

        #b4eef2 15deg,
        #12add9 25deg,
        #b4eef2 40deg,
        #12add9 55deg,
        #5e52cb 65deg,

        #1138b4 90deg,
        #1138b4 120deg,
        #5e52cb 150deg,
        #b4eef2 180deg,

        #b4eef2 195deg,
        #12add9 205deg,
        #b4eef2 220deg,
        #12add9 235deg,
        #b4eef2 245deg,

        #1138b4 270deg,
        #5e52cb 300deg,
        #5e52cb 330deg,
        #b4eef2 360deg
      );
    }
    .inner-bar{
      background: linear-gradient(185deg, #0c2a8b, #5e52cb, #b4eef2 95%, #12add9);
    }
    .background{
      background: linear-gradient(to top, #1138b4, #12add9);
      box-shadow: inset 0 30px 30px #1138b4;
    }
  }

  .obsidian{
    background: linear-gradient(5deg, #0f0f0f, #2f2f2f, #4f4f4f 95%, #8f8f8f);

    .bar{
      background-image: conic-gradient(
        #4f4f4f 0deg,

        #4f4f4f 15deg,
        #8f8f8f 25deg,
        #4f4f4f 40deg,
        #8f8f8f 55deg,
        #2f2f2f 65deg,

        #0f0f0f 90deg,
        #0f0f0f 120deg,
        #2f2f2f 150deg,
        #4f4f4f 180deg,

        #4f4f4f 195deg,
        #8f8f8f 205deg,
        #4f4f4f 220deg,
        #8f8f8f 235deg,
        #4f4f4f 245deg,

        #0f0f0f 270deg,
        #2f2f2f 300deg,
        #2f2f2f 330deg,
        #4f4f4f 360deg
      );
    }
    .inner-bar{
      background: linear-gradient(185deg, #0f0f0f, #2f2f2f, #4f4f4f 95%, #8f8f8f);
    }
    .background{
      background: linear-gradient(to top, #0f0f0f, #4f4f4f);
      box-shadow: inset 0 30px 30px #0f0f0f;
    }
  }

</style>