<script type='ts'>
  import MainMenu from './lib/main-menu.svelte';
  import PlayerSelect from './lib/player-select.svelte';
  import UpgradeScreen from './lib/upgrade-screen.svelte';
  import BattleStart from './lib/battle-start.svelte';
  import BattleOver from './lib/battle-over.svelte';
  import GameBoard from './lib/game-board.svelte';
  import Map from './lib/map.svelte';
  import Event from './lib/event-screen.svelte';
  import Glossary from './lib/glossary/glossary.svelte';
  import Farm from './lib/farm.svelte';
  import { glossary, screens } from './store';
  import Modals from "./lib/modals/modal.svelte";
  import * as Sentry from "@sentry/svelte";

  // import { auditEnemies } from "./audit";
  // auditEnemies();

  if(location.hostname == "odam.clarklavery.com"){
    Sentry.init({
      dsn: "https://547f30c6f0bb4aa2b6109c113cec103d@o4505600564854784.ingest.sentry.io/4505600568262656",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["https://odam.clarklavery.com/"],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  var glossaryOpen = true;
  glossary.open.subscribe(val => {
    glossaryOpen = val;
  });

  var fullscreen = false;

  let screensUI: Array<any> = $screens;
  screens.subscribe(val => {
    // if any vals are loading, set to visible
    val.forEach(val => {
      if(val.state == 'loading'){
        setTimeout(() => {
          if(val.state == 'loading'){
            val.state = 'visible';
          }
          screensUI = $screens;
        });
      }
    });
    //if there are no vals set to visible or loading, set the last hidden one to visible
    if(val.filter(val => val.state == 'visible' || val.state == 'loading').length == 0){
      const last = val.findLast(val => val.state == 'hidden');
      if(last){
        last.state = 'visible';
      }
    }
    screensUI = $screens;
  });

  function toggleFullscreen(){
    return;
    if(!fullscreen){
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      /* else if (elem.webkitRequestFullscreen) { // Safari
        elem.webkitRequestFullscreen();
      }
      else if (elem.msRequestFullscreen) { // IE11
        elem.msRequestFullscreen();
      } */

      fullscreen = true;
    }
    /* else{
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      fullscreen = false;
    } */
  }


  import logo72 from './assets/icons/logo-72.png';
  import logo96 from './assets/icons/logo-96.png';
  import logo120 from './assets/icons/logo-120.png';
  import logo128 from './assets/icons/logo-128.png';
  import logo144 from './assets/icons/logo-144.png';
  import logo152 from './assets/icons/logo-152.png';
  import logo180 from './assets/icons/logo-180.png';
  import logo192 from './assets/icons/logo-192.png';
  import logo384 from './assets/icons/logo-384.png';
  import logo512 from './assets/icons/logo-512.png';
</script>

<main class='' on:click={toggleFullscreen} on:keydown={()=>{}}>

  {#each screensUI as screen, index}
    <div class='full-screen {screen.state}'>
      {#if screen.state !== 'unloaded'}
      {#if screen.type == 'menu'}
        <MainMenu />
      {/if}

      {#if screen.type == 'playerselect'}
        <PlayerSelect />
      {/if}

      {#if screen.type == 'map'}
        <Map data={screen.data}/>
      {/if}

      {#if screen.type == 'event'}
        <Event data={screen.data}/>
      {/if}

      {#if screen.type == 'battle'}
        <GameBoard data={screen.data}/>
      {/if}

      {#if screen.type == 'upgrade'}
        <UpgradeScreen />
      {/if}

      {#if screen.type == 'battlestart'}
        <BattleStart data={screen.data}/>
      {/if}

      {#if screen.type == 'battleover'}
        <BattleOver data={screen.data}/>
      {/if}

      {#if screen.type == 'farm'}
        <Farm/>
      {/if}
      {/if}
    </div>
  {/each}

  <div class='full-screen no-background {glossaryOpen ? "visible" : ""}' style='z-index:111;'>
    <Glossary />
  </div>
</main>

<Modals></Modals>

<svelte:head>
  <link rel="icon" type="image/png" href={logo512} />
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="description" content="Dice Game.">
  <meta name="keywords" content="Game Dice RPG">
  <meta name="viewport"
    content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui">

  <!-- Android  -->
  <meta name="theme-color" content="#4d4365">
  <meta name="mobile-web-app-capable" content="yes">

  <!-- iOS -->
  <meta name="apple-mobile-web-app-title" content="Odam">
  <!-- <meta name="apple-mobile-web-app-capable" content="yes"> -->
  <meta name="apple-mobile-web-app-status-bar-style" content="default">

  <!-- Windows  -->
  <meta name="msapplication-navbutton-color" content="#4d4365">
  <meta name="msapplication-TileColor" content="#4d4365">
  <meta name="msapplication-TileImage" content={logo144}>
  <meta name="msapplication-config" content="browserconfig.xml">

  <!-- Pinned Sites  -->
  <meta name="application-name" content="Odam">
  <meta name="msapplication-tooltip" content="Dice Game.">
  <meta name="msapplication-starturl" content="./">

  <!-- Tap highlighting  -->
  <meta name="msapplication-tap-highlight" content="no">

  <!-- UC Mobile Browser  -->
  <meta name="full-screen" content="yes">
  <meta name="browsermode" content="application">

  <!-- Disable night mode for this page  -->
  <meta name="nightmode" content="enable">

  <!-- Layout mode -->
  <meta name="layoutmode" content="fitscreen/standard">

  <!-- Main Link Tags  -->
  <link rel="icon" type="image/png" href={logo72} sizes="72x72">

  <!-- iOS  -->
  <link href={logo120} rel="apple-touch-icon">
  <link href={logo120} rel="apple-touch-icon" sizes="120x120">
  <link href=(logo180) rel="apple-touch-icon" sizes="180x180">

  <!-- Startup Image  -->
  <link href={logo512} rel="apple-touch-startup-image">

  <!-- Pinned Tab  -->
  <!-- <link href={logo512} rel="mask-icon" size="any" color="#455357"> -->

  <!-- Android  -->
  <link href={logo192} rel="icon" sizes="192x192">
  <link href={logo128} rel="icon" sizes="128x128">

  <!-- UC Browser  -->
  <link href={logo72} rel="apple-touch-icon" sizes="72x72">
</svelte:head>

<style lang='less'>
  @import "./defines.less";

  :global(body) {
    overscroll-behavior: none;

    --light-font: #DDDDDD;
    --dark-font: #222831;

    --main: #222831;
    --secondary: #30475E;
    --white: #DDDDDD;
    --alert: #F05454;

    --border-colour: #8f8f8f;

    --dark: #222831;

/*    --background-colour: #121212;*/
/*    --background-colour-light: lighten(#121212, 10%);*/
/*    --background-colour-dark: darken(#121212, 5%);*/
    // --background-image: url('./assets/backgrounds/cave.webp');
    // --background-image: url('./assets/backgrounds/forest.webp');

    --column-size: 300px; //300
    --dice-size: 80px; //80
    --dice-gap: 10px; //15
    --dice-gap-half: 5px; //15
    --health-bar-width: 40px; //15
  }

  @media @large{
    :global(main) {
      --column-size: 240px; //300
      --dice-size: 70px; //80
      --dice-gap: 6px; //15
      --dice-gap-half: 3px; //15
      --health-bar-width: 30px; //15
      font-size: 14px;
    }
  }
  @media @medium{
    :global(main) {
      --column-size: 200px; //300
      --dice-size: 60px; //80
      --dice-gap: 6px; //15
      --dice-gap-half: 3px; //15
      --health-bar-width: 30px; //15
      font-size: 14px;
    }
  }
  @media @small{
    :global(main) {
      --column-size: 150px; //300
      --dice-size: 50px; //80
      --dice-gap: 6px; //15
      --dice-gap-half: 3px; //15
      --health-bar-width: 26px; //15
      font-size: 12px;
    }
  }

  main{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--light-font);
    background-color: #121212;
  }
  .full-screen{
    background: var(--background-colour);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;

    &.visible{
      opacity: 1;
      pointer-events: all;
    }

    &.unloaded{
      opacity: 0;
      pointer-events: none;
      transition: none;
    }

    &.no-background{
      background: none;
    }
  }
</style>
