import type { Player } from './player.type';

import type { Modifier } from './modifier';
import { ModifierPriority } from './modifier';
import { Bleed } from './status-effects/bleed';
import { Strength } from './status-effects/strength';
import { Weakness } from './status-effects/weakness';
import { Dodge } from './status-effects/dodge';
import { Stealth } from './status-effects/stealth';
import { Blind } from './status-effects/blind';
import { Stunned } from './status-effects/stunned';
import { Regeneration } from './status-effects/regeneration';
import { Block } from './status-effects/block';
import { Ward } from './status-effects/ward';
import { Barrier } from './status-effects/barrier';
import { Accuracy } from './status-effects/accuracy';
import { Vulnerable } from './status-effects/vulnerable';
import { Armoured } from './status-effects/armoured';
import { Impervious } from './status-effects/impervious';
import { Cursed } from './status-effects/cursed';
import { Poison } from './status-effects/poison';
import { Spark } from './status-effects/spark';
import { Burning } from './status-effects/burning';
import { Freezing } from './status-effects/freezing';
import { Shocked } from './status-effects/shocked';
import { Powerful } from './status-effects/powerful';
import { Witty } from './status-effects/witty';
import { Dopey } from './status-effects/dopey';
import { TwinSpell } from './status-effects/twinspell';
import { Snared } from './status-effects/snared';
import { Infusion } from './status-effects/infusion';
import { Wound } from './status-effects/wound';
import { Rested } from './status-effects/rested';
import { Ammo } from './status-effects/ammo';
import { Reactive } from './status-effects/reactive';
import { Clumsy } from './status-effects/clumsy';
import { Brittle } from './status-effects/brittle';
import { Imbue } from './status-effects/imbue';
import { Focus } from './status-effects/focus';
import { Haste } from './status-effects/haste';
import { Slow } from './status-effects/slow';
import { Rebirth } from './status-effects/rebirth';
import { Stance } from './status-effects/stance';
import { Spikes } from './status-effects/spikes';
import { Song } from './status-effects/song';
import { Distracted } from './status-effects/distracted';
import { Delay } from './status-effects/delay';
import { Grappled } from './status-effects/grappled';
import { Ethereal } from './status-effects/ethereal';
import { Petrified } from './status-effects/petrified';

export enum StatusEffect {
  Bleed = 'bleed',
  Strength = 'strength',
  Weakness = 'weakness',
  Dodge = 'dodge',
  Stealth = 'stealth',
  Blind = 'blind',
  Stunned = 'stunned',
  Regen = 'regeneration',
  Block = 'block',
  Ward = 'ward',
  Barrier = 'barrier',
  Accuracy = 'accuracy',
  Vulnerable = 'vulnerable',
  Armoured = 'armoured',
  Impervious = 'impervious',
  Cursed = 'cursed',
  Poison = 'poisoned',
  Spark = 'spark',
  Burning = 'burning',
  Freezing = 'freezing',
  Shocked = 'shocked',
  Powerful = 'powerful',
  Witty = 'witty',
  Dopey = 'dopey',
  TwinSpell = 'twinspell',
  Snared = 'snared',
  Infusion = 'infusion',
  Wound = 'wound',
  Rested = 'rested',
  Ammo = 'ammo',
  Reactive = 'reactive',
  Clumsy = 'clumsy',
  Brittle = 'brittle',
  Imbue = 'imbue',
  Focus = 'focus',
  Haste = 'haste',
  Slow = 'slow',
  Rebirth = 'rebirth',
  Stance = 'stance',
  Spikes = 'spikes',
  Song = 'song',
  Distracted = 'distracted',
  Delay = 'delay',
  Grappled = 'grappled',
  Ethereal = 'ethereal',
  Petrified = 'petrified',
}

function clone(obj){
  return JSON.parse(JSON.stringify(obj));
}

export function createModifier(owner: Player, type: StatusEffect, stacks: number, paramsIn?:any){
  if(!modifiers[type]){
    console.error('No modifer with type:', type);
  }
  var modifier: Modifier = new modifiers[type].class();

  if(stacks){
    modifier.stacks = stacks;
  }
  /*else{
    modifier.props = modifiers[type].props;
  }*/

  modifier.icon = clone(modifiers[type].icon);
  modifier.type = type;
  modifier.title = type;
  modifier.tags = clone(modifiers[type].tags);
  modifier.owner = owner;
  modifier.text = clone(modifiers[type].text);
  modifier.priority = modifiers[type].priority || 0;

  let params = paramsIn || {};
  if(params){
    Object.keys(params).forEach(param => {
      modifier[param] = params[param];
    });
  }

  if (modifier.duration > 0){
    modifier.tags.push('temporary');
  }

  modifier.init(params);

  return modifier;
};

export type StatusEffectDefinition = {
  icon: string;
  tags: string[];
  text: string;
  class: typeof Modifier;
  priority: ModifierPriority;
}
interface deff {
  [key: string]: StatusEffectDefinition
}

export const modifiers: deff = {
  [StatusEffect.Bleed]: {
    icon: 'blood',
    tags: ['bane'],
    text: 'Take 1 pure damage each time you are damaged, then fade.',
    class: Bleed,
    priority: ModifierPriority.Early
  },
  [StatusEffect.Strength]: {
    icon: 'strength',
    tags: ['boon'],
    text: 'Increase all damage dealt by the number of stacks multiplied by the number of die. Stable.',
    class: Strength,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Weakness]: {
    icon: 'weakness',
    tags: ['bane'],
    text: 'Decrease all damage dealt by the number of stacks multiplied by the number of die. Stable.',
    class: Weakness,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Dodge]: {
    icon: 'dodge',
    tags: ['boon'],
    text: 'When attacked, if the damage is higher than or equal to stacks, negate the damage and reduce stacks by the difference. Temporary.',
    class: Dodge,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Stealth]: {
    icon: 'stealth',
    tags: ['boon'],
    text: 'When attacked, reduce damage by stacks and fade. On attacking, Convert damage to Pure damage and fade.',
    class: Stealth,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Blind]: {
    icon: 'blind',
    tags: ['bane'],
    text: 'Reduce outgoing damage by half, then fade.',
    class: Blind,
    priority: ModifierPriority.Late
  },
  [StatusEffect.Stunned]: {
    icon: 'stun',
    tags: ['bane'],
    text: 'Cancel next technique used, then fade.',
    class: Stunned,
    priority: ModifierPriority.First
  },
  [StatusEffect.Regen]: {
    icon: 'regeneration',
    tags: ['boon'],
    text: 'Heal equal to the number of stacks at the start of turn, then halve.',
    class: Regeneration,
    priority: ModifierPriority.First
  },
  [StatusEffect.Block]: {
    icon: 'shield',
    tags: ['boon'],
    text: 'Reduce stacks by the damage value, then reduce damage value by the same amount. Temporary.',
    class: Block,
    priority: ModifierPriority.First
  },
  [StatusEffect.Ward]: {
    icon: 'ward',
    tags: ['boon'],
    text: 'Reduce stacks by the value of incoming Bane, then reduce the Bane by the same amount.',
    // text: 'Reduce incoming bane by the value of stacks, then reduce stacks by the damage dealt.',
    class: Ward,
    priority: ModifierPriority.Early
  },
  [StatusEffect.Barrier]: {
    icon: 'barrier',
    tags: ['boon'],
    text: 'Reduce stacks by the damage value, then reduce damage value by the same amount.',
    class: Barrier,
    priority: ModifierPriority.Early
  },
  [StatusEffect.Accuracy]: {
    icon: 'accuracy',
    tags: ['boon'],
    text: 'Attacks deal double damage, then fade.',
    class: Accuracy,
    priority: ModifierPriority.Late
  },
  [StatusEffect.Vulnerable]: {
    icon: 'vulnerable',
    tags: ['bane'],
    text: 'Incoming damage is doubled, then fade.',
    class: Vulnerable,
    priority: ModifierPriority.Late
  },
  [StatusEffect.Armoured]: {
    icon: 'armoured',
    tags: ['boon'],
    text: 'Gain block equal to the value of stacks at the end of turn. Stable.',
    class: Armoured,
    priority: ModifierPriority.First
  },/*
  [StatusEffect.Impervious]: {
    icon: 'blessed',
    tags: ['boon'],
    text: 'Resist a negative incoming effect, then fade.',
    class: Impervious,
    priority: 2
  }, */
  /* [StatusEffect.Cursed]: {
    icon: 'cursed',
    tags: ['bane'],
    text: 'Resist an incoming Boon, then fade.',
    class: Cursed,
    priority: 2
  }, */
  [StatusEffect.Poison]: {
    icon: 'poison',
    tags: ['bane'],
    text: 'Deal damage equal to stacks at the end of turn, then halve.',
    class: Poison,
    priority: ModifierPriority.Late
  },
  [StatusEffect.Spark]: {
    icon: 'lightning',
    tags: ['bane'],
    text: 'Take damage equal to half of stacks for each technique used, then halve. Halve at end of turn.',
    class: Spark,
    priority: ModifierPriority.Middle

  },
  [StatusEffect.Burning]: {
    icon: 'fire',
    tags: ['bane'],
    text: 'Take damage equal to half of stacks on reroll, then halve. Halve at end of turn.',
    class: Burning,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Freezing]: {
    icon: 'ice',
    tags: ['bane'],
    text: 'On taking damage, if health is less than stacks, shatter and die. Fade 1 for each technique used.',
    class: Freezing,
    priority: ModifierPriority.Last
  },
  [StatusEffect.Shocked]: {
    icon: 'dizzy',
    tags: ['bane'],
    text: 'Downgrades a die at the start of turn per stack. Stable.',
    class: Shocked,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Powerful]: {
    icon: 'fist',
    tags: ['boon'],
    text: 'Upgrades a die at the start of turn per stack. Stable.',
    class: Powerful,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Witty]: {
    icon: 'brain',
    tags: ['boon'],
    text: 'Increases reroll count by 1. Stable.',
    class: Witty,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Dopey]: {
    icon: 'brain',
    tags: ['bane'],
    text: 'Decreases reroll count by 1. Stable.',
    class: Dopey,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.TwinSpell]: {
    icon: 'twincast',
    tags: ['boon'],
    text: 'Repeats next technique used, then fade.',
    class: TwinSpell,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Snared]: {
    icon: 'web',
    tags: ['boon'],
    text: 'Removes & Prevents dodge stacks. On being attacked, consume all stacks and do +25% damage for each stack.',
    class: Snared,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Infusion]: {
    icon: 'infusion',
    tags: ['boon'],
    text: 'At the start of the next action phase, invoke a die of the infused type.',
    class: Infusion,
    priority: ModifierPriority.Late
  },
  [StatusEffect.Wound]: {
    icon: 'wound',
    tags: ['bane'],
    text: 'Reduce max health by the number of stacks.',
    class: Wound,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Rested]: {
    icon: 'rested',
    tags: ['boon'],
    text: 'Increase max health by the number of stacks.',
    class: Rested,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Ammo]: {
    icon: 'ammo',
    tags: ['boon', 'ammo'],
    text: 'Stored ammo will be used when attacking with projectile weapons.',
    class: Ammo,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Reactive]: {
    icon: 'reflex',
    tags: ['boon'],
    text: 'Automatically reroll any lowest face dierolls. Stable.',
    class: Reactive,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Clumsy]: {
    icon: 'clumsy',
    tags: ['bane'],
    text: 'Automatically reroll any highest face dierolls. Stable.',
    class: Clumsy,
    priority: ModifierPriority.Middle
  },
  [StatusEffect.Brittle]: {
    icon: 'brittle',
    tags: ['bane'],
    text: 'Increase damage taken by stacks, then remove.',
    priority: ModifierPriority.Early,
    class: Brittle
  },
  [StatusEffect.Imbue]: {
    icon: 'imbue',
    tags: ['boon'],
    text: 'Apply Status Effect of type, equal to the damage dealt, on attack.',
    priority: ModifierPriority.Middle,
    class: Imbue
  },
  [StatusEffect.Focus]: {
    icon: 'focus',
    tags: ['boon'],
    text: 'Boons with keyword Stable other than this will not fade. Stable.',
    class: Focus,
    priority: ModifierPriority.Last
  },
  [StatusEffect.Distracted]: {
    icon: 'distracted',
    tags: ['bane'],
    text: 'Banes with keyword Stable other than this will not fade. Stable.',
    priority: ModifierPriority.Last,
    class: Distracted
  },

  [StatusEffect.Haste]: {
    icon: 'agility',
    tags: ['boon'],
    text: 'Gain access to haste techniques, "Follow Up", "Buckle Up", "Follow Through". Stable.',
    priority: ModifierPriority.Middle,
    class: Haste
  },
  [StatusEffect.Slow]: {
    icon: 'slow',
    tags: ['bane'],
    text: 'Gives technique "Catch Up" which must be used between actions. Stable.',
    priority: ModifierPriority.Middle,
    class: Slow
  },
  [StatusEffect.Rebirth]: {
    icon: 'blessed',
    tags: ['boon'],
    text: 'On taking lethal damage, heal to the value of stacks, then vanish.',
    priority: ModifierPriority.Last,
    class: Rebirth
  },
  [StatusEffect.Stance]: {
    icon: 'stance',
    tags: ['boon'],
    text: 'Gain a stance. Can only have 1 stance active at a time.',
    priority: ModifierPriority.Early,
    class: Stance
  },
  [StatusEffect.Spikes]: {
    icon: 'spikes',
    tags: ['boon'],
    text: 'When attacked, deal damage back to the attacker equal to stacks. Temporary.',
    priority: ModifierPriority.Middle,
    class: Spikes
  },
  [StatusEffect.Song]: {
    icon: 'song',
    tags: ['song'],
    text: 'Affected by a song, Each song has its own effect.',
    priority: ModifierPriority.Middle,
    class: Song
  },
  /*
  [StatusEffect.Immortal]: {
    icon: 'immortal',
    tags: ['boon'],
    text: 'Immune to damage and negative incoming effects.',
    class: Poison
  },*/
  [StatusEffect.Delay]: {
    icon: 'delay',
    tags: [],
    priority: ModifierPriority.Middle,
    text: 'Will apply the effect at the start of the next turn.',
    class: Delay
  },
  [StatusEffect.Grappled]: {
    icon: 'grappled',
    tags: ['bane'],
    priority: ModifierPriority.Middle,
    text: 'Gives techniques "Struggle" and "Flail" to remove stacks. Take damage equal to double the stacks at the end of turn.',
    class: Grappled
  },
  [StatusEffect.Ethereal]: {
    icon: 'ethereal',
    tags: ['boon'],
    priority: ModifierPriority.Late,
    text: 'When attacked, if damage is less than or equal to stacks, negate the damage and fade. Temporary.',
    class: Ethereal
  },
  [StatusEffect.Petrified]: {
    icon: 'petrified',
    tags: ['bane'],
    priority: ModifierPriority.Middle,
    text: 'At the start of turn, give a number of die equal to stacks the stone material.',
    class: Petrified
  },
}